// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/NamedIcon.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/NamedIcon.tsx");
  import.meta.hot.lastModified = "1727919614036.6082";
}
// REMIX HMR END

import { ArrowTopRightOnSquareIcon, ExclamationTriangleIcon, InformationCircleIcon, StopIcon } from "@heroicons/react/20/solid";
import { ArrowLeftIcon, ArrowPathIcon, ArrowRightIcon, BeakerIcon, BellAlertIcon, BoltIcon, BookOpenIcon, BuildingOffice2Icon, CalendarDaysIcon, ChatBubbleLeftEllipsisIcon, CheckCircleIcon, CheckIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, ClipboardDocumentCheckIcon, ClockIcon, CloudIcon, CodeBracketSquareIcon, Cog8ToothIcon, CreditCardIcon, EnvelopeIcon, EyeIcon, FingerPrintIcon, FlagIcon, FolderIcon, GlobeAltIcon, HandRaisedIcon, HeartIcon, HomeIcon, KeyIcon, LightBulbIcon, ListBulletIcon, MagnifyingGlassIcon, PlusIcon, PlusSmallIcon, QrCodeIcon, Square2StackIcon, SquaresPlusIcon, StarIcon, TrashIcon, UserCircleIcon, UserGroupIcon, UserIcon, UserPlusIcon, WindowIcon, WrenchScrewdriverIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { CompanyIcon, hasIcon } from "@trigger.dev/companyicons";
import { ActivityIcon, HourglassIcon } from "lucide-react";
import { DynamicTriggerIcon } from "~/assets/icons/DynamicTriggerIcon";
import { EndpointIcon } from "~/assets/icons/EndpointIcon";
import { ErrorIcon } from "~/assets/icons/ErrorIcon";
import { OneTreeIcon } from "~/assets/icons/OneTreeIcon";
import { RunsIcon } from "~/assets/icons/RunsIcon";
import { SaplingIcon } from "~/assets/icons/SaplingIcon";
import { ScheduleIcon } from "~/assets/icons/ScheduleIcon";
import { TwoTreesIcon } from "~/assets/icons/TwoTreesIcon";
import { WebhookIcon } from "~/assets/icons/WebhookIcon";
import { cn } from "~/utils/cn";
import { tablerIcons } from "~/utils/tablerIcons";
import { LogoIcon } from "../LogoIcon";
import { Spinner } from "./Spinner";
import tablerSpritePath from "./tabler-sprite.svg";
const icons = {
  account: className => <UserCircleIcon className={cn("text-charcoal-400", className)} />,
  active: className => <CheckCircleIcon className={cn("text-green-500", className)} />,
  "arrow-right": className => <ArrowRightIcon className={cn("text-white", className)} />,
  "arrow-left": className => <ArrowLeftIcon className={cn("text-white", className)} />,
  background: className => <CloudIcon className={cn("text-sky-400", className)} />,
  beaker: className => <BeakerIcon className={cn("text-purple-500", className)} />,
  bell: className => <BellAlertIcon className={cn("text-amber-500", className)} />,
  billing: className => <CreditCardIcon className={cn("text-teal-500", className)} />,
  browser: className => <WindowIcon className={cn("text-text-dimmed", className)} />,
  calendar: className => <CalendarDaysIcon className={cn("text-purple-500", className)} />,
  check: className => <CheckIcon className={cn("text-text-dimmed", className)} />,
  "chevron-down": className => <ChevronDownIcon className={cn("text-text-dimmed", className)} />,
  "chevron-up": className => <ChevronUpIcon className={cn("text-text-dimmed", className)} />,
  "chevron-left": className => <ChevronLeftIcon className={cn("text-text-dimmed", className)} />,
  "chevron-right": className => <ChevronRightIcon className={cn("text-text-dimmed", className)} />,
  countdown: className => <HourglassIcon className={cn("text-amber-400", className)} />,
  clock: className => <ClockIcon className={cn("text-cyan-500", className)} />,
  close: className => <XMarkIcon className={cn("text-text-dimmed", className)} />,
  "connection-alert": className => <BellAlertIcon className={cn("text-amber-500", className)} />,
  docs: className => <BookOpenIcon className={cn("text-charcoal-400", className)} />,
  dynamic: className => <DynamicTriggerIcon className={cn("text-cyan-500", className)} />,
  error: className => <ErrorIcon className={cn("text-rose-500", className)} />,
  "external-link": className => <ArrowTopRightOnSquareIcon className={cn("text-text-dimmed", className)} />,
  flag: className => <FlagIcon className={cn("text-sky-500", className)} />,
  folder: className => <FolderIcon className={cn("text-indigo-600", className)} />,
  envelope: className => <EnvelopeIcon className={cn("text-cyan-500", className)} />,
  environment: className => <KeyIcon className={cn("text-yellow-500", className)} />,
  eye: className => <EyeIcon className={cn("text-blue-500", className)} />,
  globe: className => <GlobeAltIcon className={cn("text-fuchsia-600", className)} />,
  "hand-raised": className => <HandRaisedIcon className={cn("text-amber-400", className)} />,
  heart: className => <HeartIcon className={cn("text-rose-500", className)} />,
  house: className => <HomeIcon className={cn("text-text-dimmed", className)} />,
  id: className => <FingerPrintIcon className={cn("text-rose-200", className)} />,
  inactive: className => <XCircleIcon className={cn("text-rose-500", className)} />,
  info: className => <InformationCircleIcon className={cn("text-blue-500", className)} />,
  integration: className => <SquaresPlusIcon className={cn("text-teal-500", className)} />,
  "invite-member": className => <UserPlusIcon className={cn("text-indigo-500", className)} />,
  job: className => <WrenchScrewdriverIcon className={cn("text-indigo-500", className)} />,
  key: className => <KeyIcon className={cn("text-amber-400", className)} />,
  lightbulb: className => <LightBulbIcon className={cn("text-amber-400", className)} />,
  "clipboard-checked": className => <ClipboardDocumentCheckIcon className={cn("text-text-dimmed", className)} />,
  list: className => <ListBulletIcon className={cn("text-charcoal-400", className)} />,
  log: className => <ChatBubbleLeftEllipsisIcon className={cn("text-charcoal-400", className)} />,
  "logo-icon": className => <LogoIcon className={cn(className)} />,
  organization: className => <BuildingOffice2Icon className={cn("text-fuchsia-600", className)} />,
  plus: className => <PlusIcon className={cn("text-green-600", className)} />,
  "plus-small": className => <PlusSmallIcon className={cn("text-green-600", className)} />,
  property: className => <Cog8ToothIcon className={cn("text-charcoal-600", className)} />,
  pulse: className => <ActivityIcon className={cn("text-green-600", className)} />,
  "qr-code": className => <QrCodeIcon className={cn("text-amber-400", className)} />,
  refresh: className => <ArrowPathIcon className={cn("text-text-bright", className)} />,
  sapling: className => <SaplingIcon className={cn("text-green-500", className)} />,
  search: className => <MagnifyingGlassIcon className={cn("text-text-dimmed", className)} />,
  settings: className => <Cog8ToothIcon className={cn("text-charcoal-600", className)} />,
  spinner: className => <Spinner className={className} color="blue" />,
  "spinner-white": className => <Spinner className={className} color="white" />,
  "spinner-dark": className => <Spinner className={className} color="dark" />,
  squares: className => <Square2StackIcon className={cn("text-text-dimmed", className)} />,
  star: className => <StarIcon className={cn("text-yellow-500", className)} />,
  stop: className => <StopIcon className={cn("text-rose-500", className)} />,
  team: className => <UserGroupIcon className={cn("text-blue-500", className)} />,
  "trash-can": className => <TrashIcon className={cn("text-rose-500", className)} />,
  tree: className => <OneTreeIcon className={cn("text-green-500", className)} />,
  trees: className => <TwoTreesIcon className={cn("text-green-500", className)} />,
  trigger: className => <BoltIcon className={cn("text-orange-500", className)} />,
  user: className => <UserIcon className={cn("text-blue-600", className)} />,
  warning: className => <ExclamationTriangleIcon className={cn("text-amber-400", className)} />,
  //triggers
  "custom-event": className => <CodeBracketSquareIcon className={cn("text-toxic-600", className)} />,
  "register-source": className => <GlobeAltIcon className={cn("text-sky-500", className)} />,
  "schedule-interval": className => <ClockIcon className={cn("text-sky-500", className)} />,
  "schedule-cron": className => <ScheduleIcon className={cn("text-sky-500", className)} />,
  "schedule-dynamic": className => <ScheduleIcon className={cn("text-sky-500", className)} />,
  webhook: className => <WebhookIcon className={cn("text-pink-500", className)} />,
  endpoint: className => <EndpointIcon className={cn("text-blue-500", className)} />,
  "http-endpoint": className => <GlobeAltIcon className={cn("text-blue-500", className)} />,
  runs: className => <RunsIcon className={cn("text-lime-500", className)} />
};
export const iconNames = Object.keys(icons);
export function NamedIcon({
  name,
  className,
  fallback
}) {
  if (Object.keys(icons).includes(name)) {
    return icons[name](className);
  }
  if (hasIcon(name)) {
    return <span className={cn("grid aspect-square min-h-fit place-items-center", className)}>
        <CompanyIcon name={name} className={"h-full w-full p-[7%]"} variant="light" style={{
        shapeRendering: "geometricPrecision"
      }} />
      </span>;
  }
  if (tablerIcons.has("tabler-" + name)) {
    return <TablerIcon name={"tabler-" + name} className={className} />;
  } else if (name.startsWith("tabler-") && tablerIcons.has(name)) {
    return <TablerIcon name={name} className={className} />;
  }
  if (name === "supabase-management") {
    return <NamedIcon name="supabase" className={className} />;
  }
  if (fallback) {
    return fallback;
  }

  //default fallback icon
  return <StopIcon className={className} />;
}
_c = NamedIcon;
export function NamedIconInBox({
  name,
  className,
  fallback,
  iconClassName
}) {
  return <div className={cn("grid place-content-center rounded-sm border border-charcoal-750 bg-charcoal-850", className)}>
      <NamedIcon name={name} fallback={fallback} className={cn("h-6 w-6", iconClassName)} />
    </div>;
}
_c2 = NamedIconInBox;
export function TablerIcon({
  name,
  className
}) {
  return <svg className={cn("stroke-[1.5]", className)}>
      <use xlinkHref={`${tablerSpritePath}#${name}`} />
    </svg>;
}
_c3 = TablerIcon;
var _c, _c2, _c3;
$RefreshReg$(_c, "NamedIcon");
$RefreshReg$(_c2, "NamedIconInBox");
$RefreshReg$(_c3, "TablerIcon");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;