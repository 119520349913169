// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/tablerIcons.ts"
);
import.meta.hot.lastModified = "1727919614111.0466";
}
// REMIX HMR END

const tablerIconNames = [
  "tabler-12-hours",
  "tabler-123",
  "tabler-24-hours",
  "tabler-2fa",
  "tabler-360-view",
  "tabler-360",
  "tabler-3d-cube-sphere-off",
  "tabler-3d-cube-sphere",
  "tabler-3d-rotate",
  "tabler-a-b-2",
  "tabler-a-b-off",
  "tabler-a-b",
  "tabler-abacus-off",
  "tabler-abacus",
  "tabler-abc",
  "tabler-access-point-off",
  "tabler-access-point",
  "tabler-accessible-off-filled",
  "tabler-accessible-off",
  "tabler-accessible",
  "tabler-activity-heartbeat",
  "tabler-activity",
  "tabler-ad-2",
  "tabler-ad-circle-filled",
  "tabler-ad-circle-off",
  "tabler-ad-circle",
  "tabler-ad-filled",
  "tabler-ad-off",
  "tabler-ad",
  "tabler-address-book-off",
  "tabler-address-book",
  "tabler-adjustments-alt",
  "tabler-adjustments-bolt",
  "tabler-adjustments-cancel",
  "tabler-adjustments-check",
  "tabler-adjustments-code",
  "tabler-adjustments-cog",
  "tabler-adjustments-dollar",
  "tabler-adjustments-down",
  "tabler-adjustments-exclamation",
  "tabler-adjustments-filled",
  "tabler-adjustments-heart",
  "tabler-adjustments-horizontal",
  "tabler-adjustments-minus",
  "tabler-adjustments-off",
  "tabler-adjustments-pause",
  "tabler-adjustments-pin",
  "tabler-adjustments-plus",
  "tabler-adjustments-question",
  "tabler-adjustments-search",
  "tabler-adjustments-share",
  "tabler-adjustments-star",
  "tabler-adjustments-up",
  "tabler-adjustments-x",
  "tabler-adjustments",
  "tabler-aerial-lift",
  "tabler-affiliate-filled",
  "tabler-affiliate",
  "tabler-air-balloon",
  "tabler-air-conditioning-disabled",
  "tabler-air-conditioning",
  "tabler-air-traffic-control",
  "tabler-alarm-average",
  "tabler-alarm-filled",
  "tabler-alarm-minus-filled",
  "tabler-alarm-minus",
  "tabler-alarm-off",
  "tabler-alarm-plus-filled",
  "tabler-alarm-plus",
  "tabler-alarm-snooze-filled",
  "tabler-alarm-snooze",
  "tabler-alarm",
  "tabler-album-off",
  "tabler-album",
  "tabler-alert-circle-filled",
  "tabler-alert-circle-off",
  "tabler-alert-circle",
  "tabler-alert-hexagon-filled",
  "tabler-alert-hexagon-off",
  "tabler-alert-hexagon",
  "tabler-alert-octagon-filled",
  "tabler-alert-octagon",
  "tabler-alert-small-off",
  "tabler-alert-small",
  "tabler-alert-square-filled",
  "tabler-alert-square-rounded-filled",
  "tabler-alert-square-rounded-off",
  "tabler-alert-square-rounded",
  "tabler-alert-square",
  "tabler-alert-triangle-filled",
  "tabler-alert-triangle-off",
  "tabler-alert-triangle",
  "tabler-alien-filled",
  "tabler-alien",
  "tabler-align-box-bottom-center-filled",
  "tabler-align-box-bottom-center",
  "tabler-align-box-bottom-left-filled",
  "tabler-align-box-bottom-left",
  "tabler-align-box-bottom-right-filled",
  "tabler-align-box-bottom-right",
  "tabler-align-box-center-bottom",
  "tabler-align-box-center-middle-filled",
  "tabler-align-box-center-middle",
  "tabler-align-box-center-stretch",
  "tabler-align-box-center-top",
  "tabler-align-box-left-bottom-filled",
  "tabler-align-box-left-bottom",
  "tabler-align-box-left-middle-filled",
  "tabler-align-box-left-middle",
  "tabler-align-box-left-stretch",
  "tabler-align-box-left-top-filled",
  "tabler-align-box-left-top",
  "tabler-align-box-right-bottom-filled",
  "tabler-align-box-right-bottom",
  "tabler-align-box-right-middle-filled",
  "tabler-align-box-right-middle",
  "tabler-align-box-right-stretch",
  "tabler-align-box-right-top-filled",
  "tabler-align-box-right-top",
  "tabler-align-box-top-center-filled",
  "tabler-align-box-top-center",
  "tabler-align-box-top-left-filled",
  "tabler-align-box-top-left",
  "tabler-align-box-top-right-filled",
  "tabler-align-box-top-right",
  "tabler-align-center",
  "tabler-align-justified",
  "tabler-align-left",
  "tabler-align-right",
  "tabler-alpha",
  "tabler-alphabet-cyrillic",
  "tabler-alphabet-greek",
  "tabler-alphabet-latin",
  "tabler-alt",
  "tabler-ambulance",
  "tabler-ampersand",
  "tabler-analyze-filled",
  "tabler-analyze-off",
  "tabler-analyze",
  "tabler-anchor-off",
  "tabler-anchor",
  "tabler-angle",
  "tabler-ankh",
  "tabler-antenna-bars-1",
  "tabler-antenna-bars-2",
  "tabler-antenna-bars-3",
  "tabler-antenna-bars-4",
  "tabler-antenna-bars-5",
  "tabler-antenna-bars-off",
  "tabler-antenna-off",
  "tabler-antenna",
  "tabler-aperture-off",
  "tabler-aperture",
  "tabler-api-app-off",
  "tabler-api-app",
  "tabler-api-off",
  "tabler-api",
  "tabler-app-window-filled",
  "tabler-app-window",
  "tabler-apple",
  "tabler-apps-filled",
  "tabler-apps-off",
  "tabler-apps",
  "tabler-archery-arrow",
  "tabler-archive-filled",
  "tabler-archive-off",
  "tabler-archive",
  "tabler-armchair-2-off",
  "tabler-armchair-2",
  "tabler-armchair-off",
  "tabler-armchair",
  "tabler-arrow-autofit-content-filled",
  "tabler-arrow-autofit-content",
  "tabler-arrow-autofit-down",
  "tabler-arrow-autofit-height",
  "tabler-arrow-autofit-left",
  "tabler-arrow-autofit-right",
  "tabler-arrow-autofit-up",
  "tabler-arrow-autofit-width",
  "tabler-arrow-back-up-double",
  "tabler-arrow-back-up",
  "tabler-arrow-back",
  "tabler-arrow-badge-down-filled",
  "tabler-arrow-badge-down",
  "tabler-arrow-badge-left-filled",
  "tabler-arrow-badge-left",
  "tabler-arrow-badge-right-filled",
  "tabler-arrow-badge-right",
  "tabler-arrow-badge-up-filled",
  "tabler-arrow-badge-up",
  "tabler-arrow-bar-both",
  "tabler-arrow-bar-down",
  "tabler-arrow-bar-left",
  "tabler-arrow-bar-right",
  "tabler-arrow-bar-to-down",
  "tabler-arrow-bar-to-left",
  "tabler-arrow-bar-to-right",
  "tabler-arrow-bar-to-up",
  "tabler-arrow-bar-up",
  "tabler-arrow-bear-left-2",
  "tabler-arrow-bear-left",
  "tabler-arrow-bear-right-2",
  "tabler-arrow-bear-right",
  "tabler-arrow-big-down-filled",
  "tabler-arrow-big-down-line-filled",
  "tabler-arrow-big-down-line",
  "tabler-arrow-big-down-lines-filled",
  "tabler-arrow-big-down-lines",
  "tabler-arrow-big-down",
  "tabler-arrow-big-left-filled",
  "tabler-arrow-big-left-line-filled",
  "tabler-arrow-big-left-line",
  "tabler-arrow-big-left-lines-filled",
  "tabler-arrow-big-left-lines",
  "tabler-arrow-big-left",
  "tabler-arrow-big-right-filled",
  "tabler-arrow-big-right-line-filled",
  "tabler-arrow-big-right-line",
  "tabler-arrow-big-right-lines-filled",
  "tabler-arrow-big-right-lines",
  "tabler-arrow-big-right",
  "tabler-arrow-big-up-filled",
  "tabler-arrow-big-up-line-filled",
  "tabler-arrow-big-up-line",
  "tabler-arrow-big-up-lines-filled",
  "tabler-arrow-big-up-lines",
  "tabler-arrow-big-up",
  "tabler-arrow-bounce",
  "tabler-arrow-capsule",
  "tabler-arrow-curve-left",
  "tabler-arrow-curve-right",
  "tabler-arrow-down-bar",
  "tabler-arrow-down-circle",
  "tabler-arrow-down-left-circle",
  "tabler-arrow-down-left",
  "tabler-arrow-down-rhombus",
  "tabler-arrow-down-right-circle",
  "tabler-arrow-down-right",
  "tabler-arrow-down-square",
  "tabler-arrow-down-tail",
  "tabler-arrow-down",
  "tabler-arrow-elbow-left",
  "tabler-arrow-elbow-right",
  "tabler-arrow-fork",
  "tabler-arrow-forward-up-double",
  "tabler-arrow-forward-up",
  "tabler-arrow-forward",
  "tabler-arrow-guide",
  "tabler-arrow-iteration",
  "tabler-arrow-left-bar",
  "tabler-arrow-left-circle",
  "tabler-arrow-left-rhombus",
  "tabler-arrow-left-right",
  "tabler-arrow-left-square",
  "tabler-arrow-left-tail",
  "tabler-arrow-left",
  "tabler-arrow-loop-left-2",
  "tabler-arrow-loop-left",
  "tabler-arrow-loop-right-2",
  "tabler-arrow-loop-right",
  "tabler-arrow-merge-alt-left",
  "tabler-arrow-merge-alt-right",
  "tabler-arrow-merge-both",
  "tabler-arrow-merge-left",
  "tabler-arrow-merge-right",
  "tabler-arrow-merge",
  "tabler-arrow-move-down",
  "tabler-arrow-move-left",
  "tabler-arrow-move-right",
  "tabler-arrow-move-up",
  "tabler-arrow-narrow-down",
  "tabler-arrow-narrow-left",
  "tabler-arrow-narrow-right",
  "tabler-arrow-narrow-up",
  "tabler-arrow-ramp-left-2",
  "tabler-arrow-ramp-left-3",
  "tabler-arrow-ramp-left",
  "tabler-arrow-ramp-right-2",
  "tabler-arrow-ramp-right-3",
  "tabler-arrow-ramp-right",
  "tabler-arrow-right-bar",
  "tabler-arrow-right-circle",
  "tabler-arrow-right-rhombus",
  "tabler-arrow-right-square",
  "tabler-arrow-right-tail",
  "tabler-arrow-right",
  "tabler-arrow-rotary-first-left",
  "tabler-arrow-rotary-first-right",
  "tabler-arrow-rotary-last-left",
  "tabler-arrow-rotary-last-right",
  "tabler-arrow-rotary-left",
  "tabler-arrow-rotary-right",
  "tabler-arrow-rotary-straight",
  "tabler-arrow-roundabout-left",
  "tabler-arrow-roundabout-right",
  "tabler-arrow-sharp-turn-left",
  "tabler-arrow-sharp-turn-right",
  "tabler-arrow-up-bar",
  "tabler-arrow-up-circle",
  "tabler-arrow-up-left-circle",
  "tabler-arrow-up-left",
  "tabler-arrow-up-rhombus",
  "tabler-arrow-up-right-circle",
  "tabler-arrow-up-right",
  "tabler-arrow-up-square",
  "tabler-arrow-up-tail",
  "tabler-arrow-up",
  "tabler-arrow-wave-left-down",
  "tabler-arrow-wave-left-up",
  "tabler-arrow-wave-right-down",
  "tabler-arrow-wave-right-up",
  "tabler-arrow-zig-zag",
  "tabler-arrows-cross",
  "tabler-arrows-diagonal-2",
  "tabler-arrows-diagonal-minimize-2",
  "tabler-arrows-diagonal-minimize",
  "tabler-arrows-diagonal",
  "tabler-arrows-diff",
  "tabler-arrows-double-ne-sw",
  "tabler-arrows-double-nw-se",
  "tabler-arrows-double-se-nw",
  "tabler-arrows-double-sw-ne",
  "tabler-arrows-down-up",
  "tabler-arrows-down",
  "tabler-arrows-exchange-2",
  "tabler-arrows-exchange",
  "tabler-arrows-horizontal",
  "tabler-arrows-join-2",
  "tabler-arrows-join",
  "tabler-arrows-left-down",
  "tabler-arrows-left-right",
  "tabler-arrows-left",
  "tabler-arrows-maximize",
  "tabler-arrows-minimize",
  "tabler-arrows-move-horizontal",
  "tabler-arrows-move-vertical",
  "tabler-arrows-move",
  "tabler-arrows-random",
  "tabler-arrows-right-down",
  "tabler-arrows-right-left",
  "tabler-arrows-right",
  "tabler-arrows-shuffle-2",
  "tabler-arrows-shuffle",
  "tabler-arrows-sort",
  "tabler-arrows-split-2",
  "tabler-arrows-split",
  "tabler-arrows-transfer-down",
  "tabler-arrows-transfer-up",
  "tabler-arrows-up-down",
  "tabler-arrows-up-left",
  "tabler-arrows-up-right",
  "tabler-arrows-up",
  "tabler-arrows-vertical",
  "tabler-artboard-filled",
  "tabler-artboard-off",
  "tabler-artboard",
  "tabler-article-filled-filled",
  "tabler-article-off",
  "tabler-article",
  "tabler-aspect-ratio-filled",
  "tabler-aspect-ratio-off",
  "tabler-aspect-ratio",
  "tabler-assembly-off",
  "tabler-assembly",
  "tabler-asset",
  "tabler-asterisk-simple",
  "tabler-asterisk",
  "tabler-at-off",
  "tabler-at",
  "tabler-atom-2-filled",
  "tabler-atom-2",
  "tabler-atom-off",
  "tabler-atom",
  "tabler-augmented-reality-2",
  "tabler-augmented-reality-off",
  "tabler-augmented-reality",
  "tabler-automatic-gearbox",
  "tabler-award-filled",
  "tabler-award-off",
  "tabler-award",
  "tabler-axe",
  "tabler-axis-x",
  "tabler-axis-y",
  "tabler-baby-bottle",
  "tabler-baby-carriage",
  "tabler-backhoe",
  "tabler-backpack-off",
  "tabler-backpack",
  "tabler-backslash",
  "tabler-backspace-filled",
  "tabler-backspace",
  "tabler-badge-3d",
  "tabler-badge-4k",
  "tabler-badge-8k",
  "tabler-badge-ad",
  "tabler-badge-ar",
  "tabler-badge-cc",
  "tabler-badge-filled",
  "tabler-badge-hd",
  "tabler-badge-off",
  "tabler-badge-sd",
  "tabler-badge-tm",
  "tabler-badge-vo",
  "tabler-badge-vr",
  "tabler-badge-wc",
  "tabler-badge",
  "tabler-badges-filled",
  "tabler-badges-off",
  "tabler-badges",
  "tabler-baguette",
  "tabler-ball-american-football-off",
  "tabler-ball-american-football",
  "tabler-ball-baseball",
  "tabler-ball-basketball",
  "tabler-ball-bowling",
  "tabler-ball-football-off",
  "tabler-ball-football",
  "tabler-ball-tennis",
  "tabler-ball-volleyball",
  "tabler-balloon-filled",
  "tabler-balloon-off",
  "tabler-balloon",
  "tabler-ballpen-filled",
  "tabler-ballpen-off",
  "tabler-ballpen",
  "tabler-ban",
  "tabler-bandage-filled",
  "tabler-bandage-off",
  "tabler-bandage",
  "tabler-barbell-off",
  "tabler-barbell",
  "tabler-barcode-off",
  "tabler-barcode",
  "tabler-barrel-off",
  "tabler-barrel",
  "tabler-barrier-block-off",
  "tabler-barrier-block",
  "tabler-baseline-density-large",
  "tabler-baseline-density-medium",
  "tabler-baseline-density-small",
  "tabler-baseline",
  "tabler-basket-bolt",
  "tabler-basket-cancel",
  "tabler-basket-check",
  "tabler-basket-code",
  "tabler-basket-cog",
  "tabler-basket-discount",
  "tabler-basket-dollar",
  "tabler-basket-down",
  "tabler-basket-exclamation",
  "tabler-basket-filled",
  "tabler-basket-heart",
  "tabler-basket-minus",
  "tabler-basket-off",
  "tabler-basket-pause",
  "tabler-basket-pin",
  "tabler-basket-plus",
  "tabler-basket-question",
  "tabler-basket-search",
  "tabler-basket-share",
  "tabler-basket-star",
  "tabler-basket-up",
  "tabler-basket-x",
  "tabler-basket",
  "tabler-bat",
  "tabler-bath-filled",
  "tabler-bath-off",
  "tabler-bath",
  "tabler-battery-1-filled",
  "tabler-battery-1",
  "tabler-battery-2-filled",
  "tabler-battery-2",
  "tabler-battery-3-filled",
  "tabler-battery-3",
  "tabler-battery-4-filled",
  "tabler-battery-4",
  "tabler-battery-automotive",
  "tabler-battery-charging-2",
  "tabler-battery-charging",
  "tabler-battery-eco",
  "tabler-battery-filled",
  "tabler-battery-off",
  "tabler-battery",
  "tabler-beach-off",
  "tabler-beach",
  "tabler-bed-filled",
  "tabler-bed-flat",
  "tabler-bed-off",
  "tabler-bed",
  "tabler-beer-filled",
  "tabler-beer-off",
  "tabler-beer",
  "tabler-bell-bolt",
  "tabler-bell-cancel",
  "tabler-bell-check",
  "tabler-bell-code",
  "tabler-bell-cog",
  "tabler-bell-dollar",
  "tabler-bell-down",
  "tabler-bell-exclamation",
  "tabler-bell-filled",
  "tabler-bell-heart",
  "tabler-bell-minus-filled",
  "tabler-bell-minus",
  "tabler-bell-off",
  "tabler-bell-pause",
  "tabler-bell-pin",
  "tabler-bell-plus-filled",
  "tabler-bell-plus",
  "tabler-bell-question",
  "tabler-bell-ringing-2-filled",
  "tabler-bell-ringing-2",
  "tabler-bell-ringing-filled",
  "tabler-bell-ringing",
  "tabler-bell-school",
  "tabler-bell-search",
  "tabler-bell-share",
  "tabler-bell-star",
  "tabler-bell-up",
  "tabler-bell-x-filled",
  "tabler-bell-x",
  "tabler-bell-z-filled",
  "tabler-bell-z",
  "tabler-bell",
  "tabler-beta",
  "tabler-bible",
  "tabler-bike-off",
  "tabler-bike",
  "tabler-binary-off",
  "tabler-binary-tree-2",
  "tabler-binary-tree",
  "tabler-binary",
  "tabler-biohazard-off",
  "tabler-biohazard",
  "tabler-blade-filled",
  "tabler-blade",
  "tabler-bleach-chlorine",
  "tabler-bleach-no-chlorine",
  "tabler-bleach-off",
  "tabler-bleach",
  "tabler-blender",
  "tabler-blockquote",
  "tabler-bluetooth-connected",
  "tabler-bluetooth-off",
  "tabler-bluetooth-x",
  "tabler-bluetooth",
  "tabler-blur-off",
  "tabler-blur",
  "tabler-bmp",
  "tabler-body-scan",
  "tabler-bold-off",
  "tabler-bold",
  "tabler-bolt-off",
  "tabler-bolt",
  "tabler-bomb-filled",
  "tabler-bomb",
  "tabler-bone-off",
  "tabler-bone",
  "tabler-bong-off",
  "tabler-bong",
  "tabler-book-2",
  "tabler-book-download",
  "tabler-book-filled",
  "tabler-book-off",
  "tabler-book-upload",
  "tabler-book",
  "tabler-bookmark-ai",
  "tabler-bookmark-edit",
  "tabler-bookmark-filled",
  "tabler-bookmark-minus",
  "tabler-bookmark-off",
  "tabler-bookmark-plus",
  "tabler-bookmark-question",
  "tabler-bookmark",
  "tabler-bookmarks-filled",
  "tabler-bookmarks-off",
  "tabler-bookmarks",
  "tabler-books-off",
  "tabler-books",
  "tabler-border-all",
  "tabler-border-bottom",
  "tabler-border-corners",
  "tabler-border-horizontal",
  "tabler-border-inner",
  "tabler-border-left",
  "tabler-border-none",
  "tabler-border-outer",
  "tabler-border-radius",
  "tabler-border-right",
  "tabler-border-sides",
  "tabler-border-style-2",
  "tabler-border-style",
  "tabler-border-top",
  "tabler-border-vertical",
  "tabler-bottle-filled",
  "tabler-bottle-off",
  "tabler-bottle",
  "tabler-bounce-left-filled",
  "tabler-bounce-left",
  "tabler-bounce-right-filled",
  "tabler-bounce-right",
  "tabler-bow",
  "tabler-bowl-filled",
  "tabler-bowl",
  "tabler-box-align-bottom-filled",
  "tabler-box-align-bottom-left-filled",
  "tabler-box-align-bottom-left",
  "tabler-box-align-bottom-right-filled",
  "tabler-box-align-bottom-right",
  "tabler-box-align-bottom",
  "tabler-box-align-left-filled",
  "tabler-box-align-left",
  "tabler-box-align-right-filled",
  "tabler-box-align-right",
  "tabler-box-align-top-filled",
  "tabler-box-align-top-left-filled",
  "tabler-box-align-top-left",
  "tabler-box-align-top-right-filled",
  "tabler-box-align-top-right",
  "tabler-box-align-top",
  "tabler-box-margin",
  "tabler-box-model-2-off",
  "tabler-box-model-2",
  "tabler-box-model-off",
  "tabler-box-model",
  "tabler-box-multiple-0",
  "tabler-box-multiple-1",
  "tabler-box-multiple-2",
  "tabler-box-multiple-3",
  "tabler-box-multiple-4",
  "tabler-box-multiple-5",
  "tabler-box-multiple-6",
  "tabler-box-multiple-7",
  "tabler-box-multiple-8",
  "tabler-box-multiple-9",
  "tabler-box-multiple",
  "tabler-box-off",
  "tabler-box-padding",
  "tabler-box-seam",
  "tabler-box",
  "tabler-braces-off",
  "tabler-braces",
  "tabler-brackets-angle-off",
  "tabler-brackets-angle",
  "tabler-brackets-contain-end",
  "tabler-brackets-contain-start",
  "tabler-brackets-contain",
  "tabler-brackets-off",
  "tabler-brackets",
  "tabler-braille",
  "tabler-brain",
  "tabler-brand-4chan",
  "tabler-brand-abstract",
  "tabler-brand-adobe",
  "tabler-brand-adonis-js",
  "tabler-brand-airbnb",
  "tabler-brand-airtable",
  "tabler-brand-algolia",
  "tabler-brand-alipay",
  "tabler-brand-alpine-js",
  "tabler-brand-amazon",
  "tabler-brand-amd",
  "tabler-brand-amigo",
  "tabler-brand-among-us",
  "tabler-brand-android",
  "tabler-brand-angular",
  "tabler-brand-ansible",
  "tabler-brand-ao3",
  "tabler-brand-appgallery",
  "tabler-brand-apple-arcade",
  "tabler-brand-apple-podcast",
  "tabler-brand-apple",
  "tabler-brand-appstore",
  "tabler-brand-asana",
  "tabler-brand-auth0",
  "tabler-brand-aws",
  "tabler-brand-azure",
  "tabler-brand-backbone",
  "tabler-brand-badoo",
  "tabler-brand-baidu",
  "tabler-brand-bandcamp",
  "tabler-brand-bandlab",
  "tabler-brand-beats",
  "tabler-brand-behance",
  "tabler-brand-bilibili",
  "tabler-brand-binance",
  "tabler-brand-bing",
  "tabler-brand-bitbucket",
  "tabler-brand-blackberry",
  "tabler-brand-blender",
  "tabler-brand-blogger",
  "tabler-brand-booking",
  "tabler-brand-bootstrap",
  "tabler-brand-bulma",
  "tabler-brand-bumble",
  "tabler-brand-bunpo",
  "tabler-brand-c-sharp",
  "tabler-brand-cake",
  "tabler-brand-cakephp",
  "tabler-brand-campaignmonitor",
  "tabler-brand-carbon",
  "tabler-brand-cashapp",
  "tabler-brand-chrome",
  "tabler-brand-cinema-4d",
  "tabler-brand-citymapper",
  "tabler-brand-cloudflare",
  "tabler-brand-codecov",
  "tabler-brand-codepen",
  "tabler-brand-codesandbox",
  "tabler-brand-cohost",
  "tabler-brand-coinbase",
  "tabler-brand-comedy-central",
  "tabler-brand-coreos",
  "tabler-brand-couchdb",
  "tabler-brand-couchsurfing",
  "tabler-brand-cpp",
  "tabler-brand-craft",
  "tabler-brand-crunchbase",
  "tabler-brand-css3",
  "tabler-brand-ctemplar",
  "tabler-brand-cucumber",
  "tabler-brand-cupra",
  "tabler-brand-cypress",
  "tabler-brand-d3",
  "tabler-brand-databricks",
  "tabler-brand-days-counter",
  "tabler-brand-dcos",
  "tabler-brand-debian",
  "tabler-brand-deezer",
  "tabler-brand-deliveroo",
  "tabler-brand-deno",
  "tabler-brand-denodo",
  "tabler-brand-deviantart",
  "tabler-brand-digg",
  "tabler-brand-dingtalk",
  "tabler-brand-discord-filled",
  "tabler-brand-discord",
  "tabler-brand-disney",
  "tabler-brand-disqus",
  "tabler-brand-django",
  "tabler-brand-docker",
  "tabler-brand-doctrine",
  "tabler-brand-dolby-digital",
  "tabler-brand-douban",
  "tabler-brand-dribbble-filled",
  "tabler-brand-dribbble",
  "tabler-brand-drops",
  "tabler-brand-drupal",
  "tabler-brand-edge",
  "tabler-brand-elastic",
  "tabler-brand-electronic-arts",
  "tabler-brand-ember",
  "tabler-brand-envato",
  "tabler-brand-etsy",
  "tabler-brand-evernote",
  "tabler-brand-facebook-filled",
  "tabler-brand-facebook",
  "tabler-brand-feedly",
  "tabler-brand-figma",
  "tabler-brand-filezilla",
  "tabler-brand-finder",
  "tabler-brand-firebase",
  "tabler-brand-firefox",
  "tabler-brand-fiverr",
  "tabler-brand-flickr",
  "tabler-brand-flightradar24",
  "tabler-brand-flipboard",
  "tabler-brand-flutter",
  "tabler-brand-fortnite",
  "tabler-brand-foursquare",
  "tabler-brand-framer-motion",
  "tabler-brand-framer",
  "tabler-brand-funimation",
  "tabler-brand-gatsby",
  "tabler-brand-git",
  "tabler-brand-github-copilot",
  "tabler-brand-github-filled",
  "tabler-brand-github",
  "tabler-brand-gitlab",
  "tabler-brand-gmail",
  "tabler-brand-golang",
  "tabler-brand-google-analytics",
  "tabler-brand-google-big-query",
  "tabler-brand-google-drive",
  "tabler-brand-google-fit",
  "tabler-brand-google-home",
  "tabler-brand-google-maps",
  "tabler-brand-google-one",
  "tabler-brand-google-photos",
  "tabler-brand-google-play",
  "tabler-brand-google-podcasts",
  "tabler-brand-google",
  "tabler-brand-grammarly",
  "tabler-brand-graphql",
  "tabler-brand-gravatar",
  "tabler-brand-grindr",
  "tabler-brand-guardian",
  "tabler-brand-gumroad",
  "tabler-brand-hbo",
  "tabler-brand-headlessui",
  "tabler-brand-hexo",
  "tabler-brand-hipchat",
  "tabler-brand-html5",
  "tabler-brand-inertia",
  "tabler-brand-instagram",
  "tabler-brand-intercom",
  "tabler-brand-itch",
  "tabler-brand-javascript",
  "tabler-brand-juejin",
  "tabler-brand-kbin",
  "tabler-brand-kick",
  "tabler-brand-kickstarter",
  "tabler-brand-kotlin",
  "tabler-brand-laravel",
  "tabler-brand-lastfm",
  "tabler-brand-leetcode",
  "tabler-brand-letterboxd",
  "tabler-brand-line",
  "tabler-brand-linkedin",
  "tabler-brand-linktree",
  "tabler-brand-linqpad",
  "tabler-brand-loom",
  "tabler-brand-mailgun",
  "tabler-brand-mantine",
  "tabler-brand-mastercard",
  "tabler-brand-mastodon",
  "tabler-brand-matrix",
  "tabler-brand-mcdonalds",
  "tabler-brand-medium",
  "tabler-brand-meetup",
  "tabler-brand-mercedes",
  "tabler-brand-messenger",
  "tabler-brand-meta",
  "tabler-brand-minecraft",
  "tabler-brand-miniprogram",
  "tabler-brand-mixpanel",
  "tabler-brand-monday",
  "tabler-brand-mongodb",
  "tabler-brand-my-oppo",
  "tabler-brand-mysql",
  "tabler-brand-national-geographic",
  "tabler-brand-nem",
  "tabler-brand-netbeans",
  "tabler-brand-netease-music",
  "tabler-brand-netflix",
  "tabler-brand-nexo",
  "tabler-brand-nextcloud",
  "tabler-brand-nextjs",
  "tabler-brand-nodejs",
  "tabler-brand-nord-vpn",
  "tabler-brand-notion",
  "tabler-brand-npm",
  "tabler-brand-nuxt",
  "tabler-brand-nytimes",
  "tabler-brand-oauth",
  "tabler-brand-office",
  "tabler-brand-ok-ru",
  "tabler-brand-onedrive",
  "tabler-brand-onlyfans",
  "tabler-brand-open-source",
  "tabler-brand-openai",
  "tabler-brand-openvpn",
  "tabler-brand-opera",
  "tabler-brand-pagekit",
  "tabler-brand-parsinta",
  "tabler-brand-patreon-filled",
  "tabler-brand-patreon",
  "tabler-brand-paypal-filled",
  "tabler-brand-paypal",
  "tabler-brand-paypay",
  "tabler-brand-peanut",
  "tabler-brand-pepsi",
  "tabler-brand-php",
  "tabler-brand-picsart",
  "tabler-brand-pinterest",
  "tabler-brand-planetscale",
  "tabler-brand-pocket",
  "tabler-brand-polymer",
  "tabler-brand-powershell",
  "tabler-brand-prisma",
  "tabler-brand-producthunt",
  "tabler-brand-pushbullet",
  "tabler-brand-pushover",
  "tabler-brand-python",
  "tabler-brand-qq",
  "tabler-brand-radix-ui",
  "tabler-brand-react-native",
  "tabler-brand-react",
  "tabler-brand-reason",
  "tabler-brand-reddit",
  "tabler-brand-redhat",
  "tabler-brand-redux",
  "tabler-brand-revolut",
  "tabler-brand-rumble",
  "tabler-brand-rust",
  "tabler-brand-safari",
  "tabler-brand-samsungpass",
  "tabler-brand-sass",
  "tabler-brand-sentry",
  "tabler-brand-sharik",
  "tabler-brand-shazam",
  "tabler-brand-shopee",
  "tabler-brand-sketch",
  "tabler-brand-skype",
  "tabler-brand-slack",
  "tabler-brand-snapchat",
  "tabler-brand-snapseed",
  "tabler-brand-snowflake",
  "tabler-brand-socket-io",
  "tabler-brand-solidjs",
  "tabler-brand-soundcloud",
  "tabler-brand-spacehey",
  "tabler-brand-speedtest",
  "tabler-brand-spotify",
  "tabler-brand-stackoverflow",
  "tabler-brand-stackshare",
  "tabler-brand-steam",
  "tabler-brand-storj",
  "tabler-brand-storybook",
  "tabler-brand-storytel",
  "tabler-brand-strava",
  "tabler-brand-stripe",
  "tabler-brand-sublime-text",
  "tabler-brand-sugarizer",
  "tabler-brand-supabase",
  "tabler-brand-superhuman",
  "tabler-brand-supernova",
  "tabler-brand-surfshark",
  "tabler-brand-svelte",
  "tabler-brand-swift",
  "tabler-brand-symfony",
  "tabler-brand-tabler",
  "tabler-brand-tailwind",
  "tabler-brand-taobao",
  "tabler-brand-teams",
  "tabler-brand-ted",
  "tabler-brand-telegram",
  "tabler-brand-terraform",
  "tabler-brand-tether",
  "tabler-brand-threads",
  "tabler-brand-threejs",
  "tabler-brand-tidal",
  "tabler-brand-tiktok-filled",
  "tabler-brand-tiktok",
  "tabler-brand-tinder",
  "tabler-brand-topbuzz",
  "tabler-brand-torchain",
  "tabler-brand-toyota",
  "tabler-brand-trello",
  "tabler-brand-tripadvisor",
  "tabler-brand-tumblr",
  "tabler-brand-twilio",
  "tabler-brand-twitch",
  "tabler-brand-twitter-filled",
  "tabler-brand-twitter",
  "tabler-brand-typescript",
  "tabler-brand-uber",
  "tabler-brand-ubuntu",
  "tabler-brand-unity",
  "tabler-brand-unsplash",
  "tabler-brand-upwork",
  "tabler-brand-valorant",
  "tabler-brand-vercel",
  "tabler-brand-vimeo",
  "tabler-brand-vinted",
  "tabler-brand-visa",
  "tabler-brand-visual-studio",
  "tabler-brand-vite",
  "tabler-brand-vivaldi",
  "tabler-brand-vk",
  "tabler-brand-vlc",
  "tabler-brand-volkswagen",
  "tabler-brand-vsco",
  "tabler-brand-vscode",
  "tabler-brand-vue",
  "tabler-brand-walmart",
  "tabler-brand-waze",
  "tabler-brand-webflow",
  "tabler-brand-wechat",
  "tabler-brand-weibo",
  "tabler-brand-whatsapp",
  "tabler-brand-wikipedia",
  "tabler-brand-windows",
  "tabler-brand-windy",
  "tabler-brand-wish",
  "tabler-brand-wix",
  "tabler-brand-wordpress",
  "tabler-brand-x-filled",
  "tabler-brand-x",
  "tabler-brand-xamarin",
  "tabler-brand-xbox",
  "tabler-brand-xdeep",
  "tabler-brand-xing",
  "tabler-brand-yahoo",
  "tabler-brand-yandex",
  "tabler-brand-yatse",
  "tabler-brand-ycombinator",
  "tabler-brand-youtube-filled",
  "tabler-brand-youtube-kids",
  "tabler-brand-youtube",
  "tabler-brand-zalando",
  "tabler-brand-zapier",
  "tabler-brand-zeit",
  "tabler-brand-zhihu",
  "tabler-brand-zoom",
  "tabler-brand-zulip",
  "tabler-brand-zwift",
  "tabler-bread-off",
  "tabler-bread",
  "tabler-briefcase-2",
  "tabler-briefcase-filled",
  "tabler-briefcase-off",
  "tabler-briefcase",
  "tabler-brightness-2",
  "tabler-brightness-down-filled",
  "tabler-brightness-down",
  "tabler-brightness-half",
  "tabler-brightness-off",
  "tabler-brightness-up-filled",
  "tabler-brightness-up",
  "tabler-brightness",
  "tabler-broadcast-off",
  "tabler-broadcast",
  "tabler-browser-check",
  "tabler-browser-off",
  "tabler-browser-plus",
  "tabler-browser-x",
  "tabler-browser",
  "tabler-brush-off",
  "tabler-brush",
  "tabler-bucket-droplet",
  "tabler-bucket-off",
  "tabler-bucket",
  "tabler-bug-filled",
  "tabler-bug-off",
  "tabler-bug",
  "tabler-building-arch",
  "tabler-building-bank",
  "tabler-building-bridge-2",
  "tabler-building-bridge",
  "tabler-building-broadcast-tower",
  "tabler-building-carousel",
  "tabler-building-castle",
  "tabler-building-church",
  "tabler-building-circus",
  "tabler-building-community",
  "tabler-building-cottage",
  "tabler-building-estate",
  "tabler-building-factory-2",
  "tabler-building-factory",
  "tabler-building-fortress",
  "tabler-building-hospital",
  "tabler-building-lighthouse",
  "tabler-building-monument",
  "tabler-building-mosque",
  "tabler-building-pavilion",
  "tabler-building-skyscraper",
  "tabler-building-stadium",
  "tabler-building-store",
  "tabler-building-tunnel",
  "tabler-building-warehouse",
  "tabler-building-wind-turbine",
  "tabler-building",
  "tabler-bulb-filled",
  "tabler-bulb-off",
  "tabler-bulb",
  "tabler-bulldozer",
  "tabler-burger",
  "tabler-bus-off",
  "tabler-bus-stop",
  "tabler-bus",
  "tabler-businessplan",
  "tabler-butterfly",
  "tabler-cactus-filled",
  "tabler-cactus-off",
  "tabler-cactus",
  "tabler-cake-off",
  "tabler-cake",
  "tabler-calculator-filled",
  "tabler-calculator-off",
  "tabler-calculator",
  "tabler-calendar-bolt",
  "tabler-calendar-cancel",
  "tabler-calendar-check",
  "tabler-calendar-code",
  "tabler-calendar-cog",
  "tabler-calendar-dollar",
  "tabler-calendar-down",
  "tabler-calendar-due",
  "tabler-calendar-event",
  "tabler-calendar-exclamation",
  "tabler-calendar-filled",
  "tabler-calendar-heart",
  "tabler-calendar-minus",
  "tabler-calendar-off",
  "tabler-calendar-pause",
  "tabler-calendar-pin",
  "tabler-calendar-plus",
  "tabler-calendar-question",
  "tabler-calendar-repeat",
  "tabler-calendar-search",
  "tabler-calendar-share",
  "tabler-calendar-star",
  "tabler-calendar-stats",
  "tabler-calendar-time",
  "tabler-calendar-up",
  "tabler-calendar-x",
  "tabler-calendar",
  "tabler-camera-bolt",
  "tabler-camera-cancel",
  "tabler-camera-check",
  "tabler-camera-code",
  "tabler-camera-cog",
  "tabler-camera-dollar",
  "tabler-camera-down",
  "tabler-camera-exclamation",
  "tabler-camera-filled",
  "tabler-camera-heart",
  "tabler-camera-minus",
  "tabler-camera-off",
  "tabler-camera-pause",
  "tabler-camera-pin",
  "tabler-camera-plus",
  "tabler-camera-question",
  "tabler-camera-rotate",
  "tabler-camera-search",
  "tabler-camera-selfie",
  "tabler-camera-share",
  "tabler-camera-star",
  "tabler-camera-up",
  "tabler-camera-x",
  "tabler-camera",
  "tabler-camper",
  "tabler-campfire-filled",
  "tabler-campfire",
  "tabler-candle-filled",
  "tabler-candle",
  "tabler-candy-off",
  "tabler-candy",
  "tabler-cane",
  "tabler-cannabis",
  "tabler-capsule-filled",
  "tabler-capsule-horizontal-filled",
  "tabler-capsule-horizontal",
  "tabler-capsule",
  "tabler-capture-filled",
  "tabler-capture-off",
  "tabler-capture",
  "tabler-car-crane",
  "tabler-car-crash",
  "tabler-car-garage",
  "tabler-car-off",
  "tabler-car-suv",
  "tabler-car-turbine",
  "tabler-car",
  "tabler-caravan",
  "tabler-cardboards-off",
  "tabler-cardboards",
  "tabler-cards-filled",
  "tabler-cards",
  "tabler-caret-down-filled",
  "tabler-caret-down",
  "tabler-caret-left-filled",
  "tabler-caret-left-right-filled",
  "tabler-caret-left-right",
  "tabler-caret-left",
  "tabler-caret-right-filled",
  "tabler-caret-right",
  "tabler-caret-up-down-filled",
  "tabler-caret-up-down",
  "tabler-caret-up-filled",
  "tabler-caret-up",
  "tabler-carousel-horizontal-filled",
  "tabler-carousel-horizontal",
  "tabler-carousel-vertical-filled",
  "tabler-carousel-vertical",
  "tabler-carrot-off",
  "tabler-carrot",
  "tabler-cash-banknote-off",
  "tabler-cash-banknote",
  "tabler-cash-off",
  "tabler-cash",
  "tabler-cast-off",
  "tabler-cast",
  "tabler-cat",
  "tabler-category-2",
  "tabler-category-filled",
  "tabler-category",
  "tabler-ce-off",
  "tabler-ce",
  "tabler-cell-signal-1",
  "tabler-cell-signal-2",
  "tabler-cell-signal-3",
  "tabler-cell-signal-4",
  "tabler-cell-signal-5",
  "tabler-cell-signal-off",
  "tabler-cell",
  "tabler-certificate-2-off",
  "tabler-certificate-2",
  "tabler-certificate-off",
  "tabler-certificate",
  "tabler-chair-director",
  "tabler-chalkboard-off",
  "tabler-chalkboard",
  "tabler-charging-pile",
  "tabler-chart-arcs-3",
  "tabler-chart-arcs",
  "tabler-chart-area-filled",
  "tabler-chart-area-line-filled",
  "tabler-chart-area-line",
  "tabler-chart-area",
  "tabler-chart-arrows-vertical",
  "tabler-chart-arrows",
  "tabler-chart-bar-off",
  "tabler-chart-bar",
  "tabler-chart-bubble-filled",
  "tabler-chart-bubble",
  "tabler-chart-candle-filled",
  "tabler-chart-candle",
  "tabler-chart-circles",
  "tabler-chart-donut-2",
  "tabler-chart-donut-3",
  "tabler-chart-donut-4",
  "tabler-chart-donut-filled",
  "tabler-chart-donut",
  "tabler-chart-dots-2",
  "tabler-chart-dots-3",
  "tabler-chart-dots-filled",
  "tabler-chart-dots",
  "tabler-chart-grid-dots-filled",
  "tabler-chart-grid-dots",
  "tabler-chart-histogram",
  "tabler-chart-infographic",
  "tabler-chart-line",
  "tabler-chart-pie-2",
  "tabler-chart-pie-3",
  "tabler-chart-pie-4",
  "tabler-chart-pie-filled",
  "tabler-chart-pie-off",
  "tabler-chart-pie",
  "tabler-chart-ppf",
  "tabler-chart-radar",
  "tabler-chart-sankey",
  "tabler-chart-treemap",
  "tabler-check",
  "tabler-checkbox",
  "tabler-checklist",
  "tabler-checks",
  "tabler-checkup-list",
  "tabler-cheese",
  "tabler-chef-hat-off",
  "tabler-chef-hat",
  "tabler-cherry-filled",
  "tabler-cherry",
  "tabler-chess-bishop-filled",
  "tabler-chess-bishop",
  "tabler-chess-filled",
  "tabler-chess-king-filled",
  "tabler-chess-king",
  "tabler-chess-knight-filled",
  "tabler-chess-knight",
  "tabler-chess-queen-filled",
  "tabler-chess-queen",
  "tabler-chess-rook-filled",
  "tabler-chess-rook",
  "tabler-chess",
  "tabler-chevron-compact-down",
  "tabler-chevron-compact-left",
  "tabler-chevron-compact-right",
  "tabler-chevron-compact-up",
  "tabler-chevron-down-left",
  "tabler-chevron-down-right",
  "tabler-chevron-down",
  "tabler-chevron-left-pipe",
  "tabler-chevron-left",
  "tabler-chevron-right-pipe",
  "tabler-chevron-right",
  "tabler-chevron-up-left",
  "tabler-chevron-up-right",
  "tabler-chevron-up",
  "tabler-chevrons-down-left",
  "tabler-chevrons-down-right",
  "tabler-chevrons-down",
  "tabler-chevrons-left",
  "tabler-chevrons-right",
  "tabler-chevrons-up-left",
  "tabler-chevrons-up-right",
  "tabler-chevrons-up",
  "tabler-chisel",
  "tabler-christmas-tree-off",
  "tabler-christmas-tree",
  "tabler-circle-0-filled",
  "tabler-circle-1-filled",
  "tabler-circle-2-filled",
  "tabler-circle-3-filled",
  "tabler-circle-4-filled",
  "tabler-circle-5-filled",
  "tabler-circle-6-filled",
  "tabler-circle-7-filled",
  "tabler-circle-8-filled",
  "tabler-circle-9-filled",
  "tabler-circle-arrow-down-filled",
  "tabler-circle-arrow-down-left-filled",
  "tabler-circle-arrow-down-left",
  "tabler-circle-arrow-down-right-filled",
  "tabler-circle-arrow-down-right",
  "tabler-circle-arrow-down",
  "tabler-circle-arrow-left-filled",
  "tabler-circle-arrow-left",
  "tabler-circle-arrow-right-filled",
  "tabler-circle-arrow-right",
  "tabler-circle-arrow-up-filled",
  "tabler-circle-arrow-up-left-filled",
  "tabler-circle-arrow-up-left",
  "tabler-circle-arrow-up-right-filled",
  "tabler-circle-arrow-up-right",
  "tabler-circle-arrow-up",
  "tabler-circle-caret-down",
  "tabler-circle-caret-left",
  "tabler-circle-caret-right",
  "tabler-circle-caret-up",
  "tabler-circle-check-filled",
  "tabler-circle-check",
  "tabler-circle-chevron-down",
  "tabler-circle-chevron-left",
  "tabler-circle-chevron-right",
  "tabler-circle-chevron-up",
  "tabler-circle-chevrons-down",
  "tabler-circle-chevrons-left",
  "tabler-circle-chevrons-right",
  "tabler-circle-chevrons-up",
  "tabler-circle-dashed-number-0",
  "tabler-circle-dashed-number-1",
  "tabler-circle-dashed-number-2",
  "tabler-circle-dashed-number-3",
  "tabler-circle-dashed-number-4",
  "tabler-circle-dashed-number-5",
  "tabler-circle-dashed-number-6",
  "tabler-circle-dashed-number-7",
  "tabler-circle-dashed-number-8",
  "tabler-circle-dashed-number-9",
  "tabler-circle-dashed-x",
  "tabler-circle-dashed",
  "tabler-circle-dot-filled",
  "tabler-circle-dot",
  "tabler-circle-dotted",
  "tabler-circle-filled",
  "tabler-circle-half-2",
  "tabler-circle-half-vertical",
  "tabler-circle-half",
  "tabler-circle-key-filled",
  "tabler-circle-key",
  "tabler-circle-letter-a",
  "tabler-circle-letter-b",
  "tabler-circle-letter-c",
  "tabler-circle-letter-d",
  "tabler-circle-letter-e",
  "tabler-circle-letter-f",
  "tabler-circle-letter-g",
  "tabler-circle-letter-h",
  "tabler-circle-letter-i",
  "tabler-circle-letter-j",
  "tabler-circle-letter-k",
  "tabler-circle-letter-l",
  "tabler-circle-letter-m",
  "tabler-circle-letter-n",
  "tabler-circle-letter-o",
  "tabler-circle-letter-p",
  "tabler-circle-letter-q",
  "tabler-circle-letter-r",
  "tabler-circle-letter-s",
  "tabler-circle-letter-t",
  "tabler-circle-letter-u",
  "tabler-circle-letter-v",
  "tabler-circle-letter-w",
  "tabler-circle-letter-x",
  "tabler-circle-letter-y",
  "tabler-circle-letter-z",
  "tabler-circle-minus-2",
  "tabler-circle-minus",
  "tabler-circle-number-0",
  "tabler-circle-number-1",
  "tabler-circle-number-2",
  "tabler-circle-number-3",
  "tabler-circle-number-4",
  "tabler-circle-number-5",
  "tabler-circle-number-6",
  "tabler-circle-number-7",
  "tabler-circle-number-8",
  "tabler-circle-number-9",
  "tabler-circle-off",
  "tabler-circle-plus-2",
  "tabler-circle-plus",
  "tabler-circle-rectangle-off",
  "tabler-circle-rectangle",
  "tabler-circle-square",
  "tabler-circle-triangle",
  "tabler-circle-x-filled",
  "tabler-circle-x",
  "tabler-circle",
  "tabler-circles-filled",
  "tabler-circles-relation",
  "tabler-circles",
  "tabler-circuit-ammeter",
  "tabler-circuit-battery",
  "tabler-circuit-bulb",
  "tabler-circuit-capacitor-polarized",
  "tabler-circuit-capacitor",
  "tabler-circuit-cell-plus",
  "tabler-circuit-cell",
  "tabler-circuit-changeover",
  "tabler-circuit-diode-zener",
  "tabler-circuit-diode",
  "tabler-circuit-ground-digital",
  "tabler-circuit-ground",
  "tabler-circuit-inductor",
  "tabler-circuit-motor",
  "tabler-circuit-pushbutton",
  "tabler-circuit-resistor",
  "tabler-circuit-switch-closed",
  "tabler-circuit-switch-open",
  "tabler-circuit-voltmeter",
  "tabler-clear-all",
  "tabler-clear-formatting",
  "tabler-click",
  "tabler-clipboard-check",
  "tabler-clipboard-copy",
  "tabler-clipboard-data",
  "tabler-clipboard-heart",
  "tabler-clipboard-list",
  "tabler-clipboard-off",
  "tabler-clipboard-plus",
  "tabler-clipboard-text",
  "tabler-clipboard-typography",
  "tabler-clipboard-x",
  "tabler-clipboard",
  "tabler-clock-12",
  "tabler-clock-2",
  "tabler-clock-24",
  "tabler-clock-bolt",
  "tabler-clock-cancel",
  "tabler-clock-check",
  "tabler-clock-code",
  "tabler-clock-cog",
  "tabler-clock-dollar",
  "tabler-clock-down",
  "tabler-clock-edit",
  "tabler-clock-exclamation",
  "tabler-clock-filled",
  "tabler-clock-heart",
  "tabler-clock-hour-1",
  "tabler-clock-hour-10",
  "tabler-clock-hour-11",
  "tabler-clock-hour-12",
  "tabler-clock-hour-2",
  "tabler-clock-hour-3",
  "tabler-clock-hour-4",
  "tabler-clock-hour-5",
  "tabler-clock-hour-6",
  "tabler-clock-hour-7",
  "tabler-clock-hour-8",
  "tabler-clock-hour-9",
  "tabler-clock-minus",
  "tabler-clock-off",
  "tabler-clock-pause",
  "tabler-clock-pin",
  "tabler-clock-play",
  "tabler-clock-plus",
  "tabler-clock-question",
  "tabler-clock-record",
  "tabler-clock-search",
  "tabler-clock-share",
  "tabler-clock-shield",
  "tabler-clock-star",
  "tabler-clock-stop",
  "tabler-clock-up",
  "tabler-clock-x",
  "tabler-clock",
  "tabler-clothes-rack-off",
  "tabler-clothes-rack",
  "tabler-cloud-bolt",
  "tabler-cloud-cancel",
  "tabler-cloud-check",
  "tabler-cloud-code",
  "tabler-cloud-cog",
  "tabler-cloud-computing",
  "tabler-cloud-data-connection",
  "tabler-cloud-dollar",
  "tabler-cloud-down",
  "tabler-cloud-download",
  "tabler-cloud-exclamation",
  "tabler-cloud-filled",
  "tabler-cloud-fog",
  "tabler-cloud-heart",
  "tabler-cloud-lock-open",
  "tabler-cloud-lock",
  "tabler-cloud-minus",
  "tabler-cloud-network",
  "tabler-cloud-off",
  "tabler-cloud-pause",
  "tabler-cloud-pin",
  "tabler-cloud-plus",
  "tabler-cloud-question",
  "tabler-cloud-rain",
  "tabler-cloud-search",
  "tabler-cloud-share",
  "tabler-cloud-snow",
  "tabler-cloud-star",
  "tabler-cloud-storm",
  "tabler-cloud-up",
  "tabler-cloud-upload",
  "tabler-cloud-x",
  "tabler-cloud",
  "tabler-clover-2",
  "tabler-clover",
  "tabler-clubs-filled",
  "tabler-clubs",
  "tabler-code-asterix",
  "tabler-code-circle-2",
  "tabler-code-circle",
  "tabler-code-dots",
  "tabler-code-minus",
  "tabler-code-off",
  "tabler-code-plus",
  "tabler-code",
  "tabler-coffee-off",
  "tabler-coffee",
  "tabler-coffin",
  "tabler-coin-bitcoin-filled",
  "tabler-coin-bitcoin",
  "tabler-coin-euro-filled",
  "tabler-coin-euro",
  "tabler-coin-filled",
  "tabler-coin-monero-filled",
  "tabler-coin-monero",
  "tabler-coin-off",
  "tabler-coin-pound-filled",
  "tabler-coin-pound",
  "tabler-coin-rupee-filled",
  "tabler-coin-rupee",
  "tabler-coin-taka-filled",
  "tabler-coin-taka",
  "tabler-coin-yen-filled",
  "tabler-coin-yen",
  "tabler-coin-yuan-filled",
  "tabler-coin-yuan",
  "tabler-coin",
  "tabler-coins",
  "tabler-color-filter",
  "tabler-color-picker-off",
  "tabler-color-picker",
  "tabler-color-swatch-off",
  "tabler-color-swatch",
  "tabler-column-insert-left",
  "tabler-column-insert-right",
  "tabler-column-remove",
  "tabler-columns-1",
  "tabler-columns-2",
  "tabler-columns-3",
  "tabler-columns-off",
  "tabler-columns",
  "tabler-comet",
  "tabler-command-off",
  "tabler-command",
  "tabler-compass-filled",
  "tabler-compass-off",
  "tabler-compass",
  "tabler-components-off",
  "tabler-components",
  "tabler-cone-2",
  "tabler-cone-off",
  "tabler-cone-plus",
  "tabler-cone",
  "tabler-confetti-off",
  "tabler-confetti",
  "tabler-confucius",
  "tabler-container-off",
  "tabler-container",
  "tabler-contrast-2-off",
  "tabler-contrast-2",
  "tabler-contrast-off",
  "tabler-contrast",
  "tabler-cooker",
  "tabler-cookie-man",
  "tabler-cookie-off",
  "tabler-cookie",
  "tabler-copy-off",
  "tabler-copy",
  "tabler-copyleft-filled",
  "tabler-copyleft-off",
  "tabler-copyleft",
  "tabler-copyright-filled",
  "tabler-copyright-off",
  "tabler-copyright",
  "tabler-corner-down-left-double",
  "tabler-corner-down-left",
  "tabler-corner-down-right-double",
  "tabler-corner-down-right",
  "tabler-corner-left-down-double",
  "tabler-corner-left-down",
  "tabler-corner-left-up-double",
  "tabler-corner-left-up",
  "tabler-corner-right-down-double",
  "tabler-corner-right-down",
  "tabler-corner-right-up-double",
  "tabler-corner-right-up",
  "tabler-corner-up-left-double",
  "tabler-corner-up-left",
  "tabler-corner-up-right-double",
  "tabler-corner-up-right",
  "tabler-cpu-2",
  "tabler-cpu-off",
  "tabler-cpu",
  "tabler-crane-off",
  "tabler-crane",
  "tabler-creative-commons-by",
  "tabler-creative-commons-nc",
  "tabler-creative-commons-nd",
  "tabler-creative-commons-off",
  "tabler-creative-commons-sa",
  "tabler-creative-commons-zero",
  "tabler-creative-commons",
  "tabler-credit-card-filled",
  "tabler-credit-card-off",
  "tabler-credit-card",
  "tabler-cricket",
  "tabler-crop",
  "tabler-cross-filled",
  "tabler-cross-off",
  "tabler-cross",
  "tabler-crosshair",
  "tabler-crown-off",
  "tabler-crown",
  "tabler-crutches-off",
  "tabler-crutches",
  "tabler-crystal-ball",
  "tabler-csv",
  "tabler-cube-off",
  "tabler-cube-plus",
  "tabler-cube-send",
  "tabler-cube-unfolded",
  "tabler-cube",
  "tabler-cup-off",
  "tabler-cup",
  "tabler-curling",
  "tabler-curly-loop",
  "tabler-currency-afghani",
  "tabler-currency-bahraini",
  "tabler-currency-baht",
  "tabler-currency-bitcoin",
  "tabler-currency-cent",
  "tabler-currency-dinar",
  "tabler-currency-dirham",
  "tabler-currency-dogecoin",
  "tabler-currency-dollar-australian",
  "tabler-currency-dollar-brunei",
  "tabler-currency-dollar-canadian",
  "tabler-currency-dollar-guyanese",
  "tabler-currency-dollar-off",
  "tabler-currency-dollar-singapore",
  "tabler-currency-dollar-zimbabwean",
  "tabler-currency-dollar",
  "tabler-currency-dong",
  "tabler-currency-dram",
  "tabler-currency-ethereum",
  "tabler-currency-euro-off",
  "tabler-currency-euro",
  "tabler-currency-florin",
  "tabler-currency-forint",
  "tabler-currency-frank",
  "tabler-currency-guarani",
  "tabler-currency-hryvnia",
  "tabler-currency-iranian-rial",
  "tabler-currency-kip",
  "tabler-currency-krone-czech",
  "tabler-currency-krone-danish",
  "tabler-currency-krone-swedish",
  "tabler-currency-lari",
  "tabler-currency-leu",
  "tabler-currency-lira",
  "tabler-currency-litecoin",
  "tabler-currency-lyd",
  "tabler-currency-manat",
  "tabler-currency-monero",
  "tabler-currency-naira",
  "tabler-currency-nano",
  "tabler-currency-off",
  "tabler-currency-paanga",
  "tabler-currency-peso",
  "tabler-currency-pound-off",
  "tabler-currency-pound",
  "tabler-currency-quetzal",
  "tabler-currency-real",
  "tabler-currency-renminbi",
  "tabler-currency-ripple",
  "tabler-currency-riyal",
  "tabler-currency-rubel",
  "tabler-currency-rufiyaa",
  "tabler-currency-rupee-nepalese",
  "tabler-currency-rupee",
  "tabler-currency-shekel",
  "tabler-currency-solana",
  "tabler-currency-som",
  "tabler-currency-taka",
  "tabler-currency-tenge",
  "tabler-currency-tugrik",
  "tabler-currency-won",
  "tabler-currency-yen-off",
  "tabler-currency-yen",
  "tabler-currency-yuan",
  "tabler-currency-zloty",
  "tabler-currency",
  "tabler-current-location-off",
  "tabler-current-location",
  "tabler-cursor-off",
  "tabler-cursor-text",
  "tabler-cut",
  "tabler-cylinder-off",
  "tabler-cylinder-plus",
  "tabler-cylinder",
  "tabler-dashboard-off",
  "tabler-dashboard",
  "tabler-database-cog",
  "tabler-database-dollar",
  "tabler-database-edit",
  "tabler-database-exclamation",
  "tabler-database-export",
  "tabler-database-heart",
  "tabler-database-import",
  "tabler-database-leak",
  "tabler-database-minus",
  "tabler-database-off",
  "tabler-database-plus",
  "tabler-database-search",
  "tabler-database-share",
  "tabler-database-star",
  "tabler-database-x",
  "tabler-database",
  "tabler-decimal",
  "tabler-deer",
  "tabler-delta",
  "tabler-dental-broken",
  "tabler-dental-off",
  "tabler-dental",
  "tabler-deselect",
  "tabler-details-off",
  "tabler-details",
  "tabler-device-airpods-case",
  "tabler-device-airpods",
  "tabler-device-airtag",
  "tabler-device-analytics",
  "tabler-device-audio-tape",
  "tabler-device-camera-phone",
  "tabler-device-cctv-off",
  "tabler-device-cctv",
  "tabler-device-computer-camera-off",
  "tabler-device-computer-camera",
  "tabler-device-desktop-analytics",
  "tabler-device-desktop-bolt",
  "tabler-device-desktop-cancel",
  "tabler-device-desktop-check",
  "tabler-device-desktop-code",
  "tabler-device-desktop-cog",
  "tabler-device-desktop-dollar",
  "tabler-device-desktop-down",
  "tabler-device-desktop-exclamation",
  "tabler-device-desktop-heart",
  "tabler-device-desktop-minus",
  "tabler-device-desktop-off",
  "tabler-device-desktop-pause",
  "tabler-device-desktop-pin",
  "tabler-device-desktop-plus",
  "tabler-device-desktop-question",
  "tabler-device-desktop-search",
  "tabler-device-desktop-share",
  "tabler-device-desktop-star",
  "tabler-device-desktop-up",
  "tabler-device-desktop-x",
  "tabler-device-desktop",
  "tabler-device-floppy",
  "tabler-device-gamepad-2",
  "tabler-device-gamepad-3",
  "tabler-device-gamepad",
  "tabler-device-heart-monitor-filled",
  "tabler-device-heart-monitor",
  "tabler-device-imac-bolt",
  "tabler-device-imac-cancel",
  "tabler-device-imac-check",
  "tabler-device-imac-code",
  "tabler-device-imac-cog",
  "tabler-device-imac-dollar",
  "tabler-device-imac-down",
  "tabler-device-imac-exclamation",
  "tabler-device-imac-heart",
  "tabler-device-imac-minus",
  "tabler-device-imac-off",
  "tabler-device-imac-pause",
  "tabler-device-imac-pin",
  "tabler-device-imac-plus",
  "tabler-device-imac-question",
  "tabler-device-imac-search",
  "tabler-device-imac-share",
  "tabler-device-imac-star",
  "tabler-device-imac-up",
  "tabler-device-imac-x",
  "tabler-device-imac",
  "tabler-device-ipad-bolt",
  "tabler-device-ipad-cancel",
  "tabler-device-ipad-check",
  "tabler-device-ipad-code",
  "tabler-device-ipad-cog",
  "tabler-device-ipad-dollar",
  "tabler-device-ipad-down",
  "tabler-device-ipad-exclamation",
  "tabler-device-ipad-heart",
  "tabler-device-ipad-horizontal-bolt",
  "tabler-device-ipad-horizontal-cancel",
  "tabler-device-ipad-horizontal-check",
  "tabler-device-ipad-horizontal-code",
  "tabler-device-ipad-horizontal-cog",
  "tabler-device-ipad-horizontal-dollar",
  "tabler-device-ipad-horizontal-down",
  "tabler-device-ipad-horizontal-exclamation",
  "tabler-device-ipad-horizontal-heart",
  "tabler-device-ipad-horizontal-minus",
  "tabler-device-ipad-horizontal-off",
  "tabler-device-ipad-horizontal-pause",
  "tabler-device-ipad-horizontal-pin",
  "tabler-device-ipad-horizontal-plus",
  "tabler-device-ipad-horizontal-question",
  "tabler-device-ipad-horizontal-search",
  "tabler-device-ipad-horizontal-share",
  "tabler-device-ipad-horizontal-star",
  "tabler-device-ipad-horizontal-up",
  "tabler-device-ipad-horizontal-x",
  "tabler-device-ipad-horizontal",
  "tabler-device-ipad-minus",
  "tabler-device-ipad-off",
  "tabler-device-ipad-pause",
  "tabler-device-ipad-pin",
  "tabler-device-ipad-plus",
  "tabler-device-ipad-question",
  "tabler-device-ipad-search",
  "tabler-device-ipad-share",
  "tabler-device-ipad-star",
  "tabler-device-ipad-up",
  "tabler-device-ipad-x",
  "tabler-device-ipad",
  "tabler-device-landline-phone",
  "tabler-device-laptop-off",
  "tabler-device-laptop",
  "tabler-device-mobile-bolt",
  "tabler-device-mobile-cancel",
  "tabler-device-mobile-charging",
  "tabler-device-mobile-check",
  "tabler-device-mobile-code",
  "tabler-device-mobile-cog",
  "tabler-device-mobile-dollar",
  "tabler-device-mobile-down",
  "tabler-device-mobile-exclamation",
  "tabler-device-mobile-filled",
  "tabler-device-mobile-heart",
  "tabler-device-mobile-message",
  "tabler-device-mobile-minus",
  "tabler-device-mobile-off",
  "tabler-device-mobile-pause",
  "tabler-device-mobile-pin",
  "tabler-device-mobile-plus",
  "tabler-device-mobile-question",
  "tabler-device-mobile-rotated",
  "tabler-device-mobile-search",
  "tabler-device-mobile-share",
  "tabler-device-mobile-star",
  "tabler-device-mobile-up",
  "tabler-device-mobile-vibration",
  "tabler-device-mobile-x",
  "tabler-device-mobile",
  "tabler-device-nintendo-off",
  "tabler-device-nintendo",
  "tabler-device-projector",
  "tabler-device-remote",
  "tabler-device-sd-card",
  "tabler-device-sim-1",
  "tabler-device-sim-2",
  "tabler-device-sim-3",
  "tabler-device-sim",
  "tabler-device-speaker-off",
  "tabler-device-speaker",
  "tabler-device-tablet-bolt",
  "tabler-device-tablet-cancel",
  "tabler-device-tablet-check",
  "tabler-device-tablet-code",
  "tabler-device-tablet-cog",
  "tabler-device-tablet-dollar",
  "tabler-device-tablet-down",
  "tabler-device-tablet-exclamation",
  "tabler-device-tablet-filled",
  "tabler-device-tablet-heart",
  "tabler-device-tablet-minus",
  "tabler-device-tablet-off",
  "tabler-device-tablet-pause",
  "tabler-device-tablet-pin",
  "tabler-device-tablet-plus",
  "tabler-device-tablet-question",
  "tabler-device-tablet-search",
  "tabler-device-tablet-share",
  "tabler-device-tablet-star",
  "tabler-device-tablet-up",
  "tabler-device-tablet-x",
  "tabler-device-tablet",
  "tabler-device-tv-off",
  "tabler-device-tv-old",
  "tabler-device-tv",
  "tabler-device-usb",
  "tabler-device-vision-pro",
  "tabler-device-watch-bolt",
  "tabler-device-watch-cancel",
  "tabler-device-watch-check",
  "tabler-device-watch-code",
  "tabler-device-watch-cog",
  "tabler-device-watch-dollar",
  "tabler-device-watch-down",
  "tabler-device-watch-exclamation",
  "tabler-device-watch-heart",
  "tabler-device-watch-minus",
  "tabler-device-watch-off",
  "tabler-device-watch-pause",
  "tabler-device-watch-pin",
  "tabler-device-watch-plus",
  "tabler-device-watch-question",
  "tabler-device-watch-search",
  "tabler-device-watch-share",
  "tabler-device-watch-star",
  "tabler-device-watch-stats-2",
  "tabler-device-watch-stats",
  "tabler-device-watch-up",
  "tabler-device-watch-x",
  "tabler-device-watch",
  "tabler-devices-2",
  "tabler-devices-bolt",
  "tabler-devices-cancel",
  "tabler-devices-check",
  "tabler-devices-code",
  "tabler-devices-cog",
  "tabler-devices-dollar",
  "tabler-devices-down",
  "tabler-devices-exclamation",
  "tabler-devices-heart",
  "tabler-devices-minus",
  "tabler-devices-off",
  "tabler-devices-pause",
  "tabler-devices-pc-off",
  "tabler-devices-pc",
  "tabler-devices-pin",
  "tabler-devices-plus",
  "tabler-devices-question",
  "tabler-devices-search",
  "tabler-devices-share",
  "tabler-devices-star",
  "tabler-devices-up",
  "tabler-devices-x",
  "tabler-devices",
  "tabler-diabolo-off",
  "tabler-diabolo-plus",
  "tabler-diabolo",
  "tabler-dialpad-filled",
  "tabler-dialpad-off",
  "tabler-dialpad",
  "tabler-diamond-filled",
  "tabler-diamond-off",
  "tabler-diamond",
  "tabler-diamonds-filled",
  "tabler-diamonds",
  "tabler-dice-1-filled",
  "tabler-dice-1",
  "tabler-dice-2-filled",
  "tabler-dice-2",
  "tabler-dice-3-filled",
  "tabler-dice-3",
  "tabler-dice-4-filled",
  "tabler-dice-4",
  "tabler-dice-5-filled",
  "tabler-dice-5",
  "tabler-dice-6-filled",
  "tabler-dice-6",
  "tabler-dice-filled",
  "tabler-dice",
  "tabler-dimensions",
  "tabler-direction-horizontal",
  "tabler-direction-sign-filled",
  "tabler-direction-sign-off",
  "tabler-direction-sign",
  "tabler-direction",
  "tabler-directions-off",
  "tabler-directions",
  "tabler-disabled-2",
  "tabler-disabled-off",
  "tabler-disabled",
  "tabler-disc-golf",
  "tabler-disc-off",
  "tabler-disc",
  "tabler-discount-2-off",
  "tabler-discount-2",
  "tabler-discount-check-filled",
  "tabler-discount-check",
  "tabler-discount-off",
  "tabler-discount",
  "tabler-divide",
  "tabler-dna-2-off",
  "tabler-dna-2",
  "tabler-dna-off",
  "tabler-dna",
  "tabler-dog-bowl",
  "tabler-dog",
  "tabler-door-enter",
  "tabler-door-exit",
  "tabler-door-off",
  "tabler-door",
  "tabler-dots-circle-horizontal",
  "tabler-dots-diagonal-2",
  "tabler-dots-diagonal",
  "tabler-dots-vertical",
  "tabler-dots",
  "tabler-download-off",
  "tabler-download",
  "tabler-drag-drop-2",
  "tabler-drag-drop",
  "tabler-drone-off",
  "tabler-drone",
  "tabler-drop-circle",
  "tabler-droplet-bolt",
  "tabler-droplet-cancel",
  "tabler-droplet-check",
  "tabler-droplet-code",
  "tabler-droplet-cog",
  "tabler-droplet-dollar",
  "tabler-droplet-down",
  "tabler-droplet-exclamation",
  "tabler-droplet-filled",
  "tabler-droplet-half-2-filled",
  "tabler-droplet-half-2",
  "tabler-droplet-half-filled",
  "tabler-droplet-half",
  "tabler-droplet-heart",
  "tabler-droplet-minus",
  "tabler-droplet-off",
  "tabler-droplet-pause",
  "tabler-droplet-pin",
  "tabler-droplet-plus",
  "tabler-droplet-question",
  "tabler-droplet-search",
  "tabler-droplet-share",
  "tabler-droplet-star",
  "tabler-droplet-up",
  "tabler-droplet-x",
  "tabler-droplet",
  "tabler-droplets",
  "tabler-dual-screen",
  "tabler-e-passport",
  "tabler-ear-off",
  "tabler-ear",
  "tabler-ease-in-control-point",
  "tabler-ease-in-out-control-points",
  "tabler-ease-in-out",
  "tabler-ease-in",
  "tabler-ease-out-control-point",
  "tabler-ease-out",
  "tabler-edit-circle-off",
  "tabler-edit-circle",
  "tabler-edit-off",
  "tabler-edit",
  "tabler-egg-cracked",
  "tabler-egg-filled",
  "tabler-egg-fried",
  "tabler-egg-off",
  "tabler-egg",
  "tabler-eggs",
  "tabler-elevator-off",
  "tabler-elevator",
  "tabler-emergency-bed",
  "tabler-empathize-off",
  "tabler-empathize",
  "tabler-emphasis",
  "tabler-engine-off",
  "tabler-engine",
  "tabler-equal-double",
  "tabler-equal-not",
  "tabler-equal",
  "tabler-eraser-off",
  "tabler-eraser",
  "tabler-error-404-off",
  "tabler-error-404",
  "tabler-escalator-down",
  "tabler-escalator-up",
  "tabler-escalator",
  "tabler-exchange-off",
  "tabler-exchange",
  "tabler-exclamation-circle",
  "tabler-exclamation-mark-off",
  "tabler-exclamation-mark",
  "tabler-explicit-off",
  "tabler-explicit",
  "tabler-exposure-0",
  "tabler-exposure-minus-1",
  "tabler-exposure-minus-2",
  "tabler-exposure-off",
  "tabler-exposure-plus-1",
  "tabler-exposure-plus-2",
  "tabler-exposure",
  "tabler-external-link-off",
  "tabler-external-link",
  "tabler-eye-bolt",
  "tabler-eye-cancel",
  "tabler-eye-check",
  "tabler-eye-closed",
  "tabler-eye-code",
  "tabler-eye-cog",
  "tabler-eye-discount",
  "tabler-eye-dollar",
  "tabler-eye-down",
  "tabler-eye-edit",
  "tabler-eye-exclamation",
  "tabler-eye-filled",
  "tabler-eye-heart",
  "tabler-eye-minus",
  "tabler-eye-off",
  "tabler-eye-pause",
  "tabler-eye-pin",
  "tabler-eye-plus",
  "tabler-eye-question",
  "tabler-eye-search",
  "tabler-eye-share",
  "tabler-eye-star",
  "tabler-eye-table",
  "tabler-eye-up",
  "tabler-eye-x",
  "tabler-eye",
  "tabler-eyeglass-2",
  "tabler-eyeglass-off",
  "tabler-eyeglass",
  "tabler-face-id-error",
  "tabler-face-id",
  "tabler-face-mask-off",
  "tabler-face-mask",
  "tabler-fall",
  "tabler-feather-off",
  "tabler-feather",
  "tabler-fence-off",
  "tabler-fence",
  "tabler-fidget-spinner",
  "tabler-file-3d",
  "tabler-file-alert",
  "tabler-file-analytics",
  "tabler-file-arrow-left",
  "tabler-file-arrow-right",
  "tabler-file-barcode",
  "tabler-file-broken",
  "tabler-file-certificate",
  "tabler-file-chart",
  "tabler-file-check",
  "tabler-file-code-2",
  "tabler-file-code",
  "tabler-file-cv",
  "tabler-file-database",
  "tabler-file-delta",
  "tabler-file-description",
  "tabler-file-diff",
  "tabler-file-digit",
  "tabler-file-dislike",
  "tabler-file-dollar",
  "tabler-file-dots",
  "tabler-file-download",
  "tabler-file-euro",
  "tabler-file-export",
  "tabler-file-filled",
  "tabler-file-function",
  "tabler-file-horizontal",
  "tabler-file-import",
  "tabler-file-infinity",
  "tabler-file-info",
  "tabler-file-invoice",
  "tabler-file-lambda",
  "tabler-file-like",
  "tabler-file-minus",
  "tabler-file-music",
  "tabler-file-off",
  "tabler-file-orientation",
  "tabler-file-pencil",
  "tabler-file-percent",
  "tabler-file-phone",
  "tabler-file-plus",
  "tabler-file-power",
  "tabler-file-report",
  "tabler-file-rss",
  "tabler-file-scissors",
  "tabler-file-search",
  "tabler-file-settings",
  "tabler-file-shredder",
  "tabler-file-signal",
  "tabler-file-spreadsheet",
  "tabler-file-stack",
  "tabler-file-star",
  "tabler-file-symlink",
  "tabler-file-text-ai",
  "tabler-file-text",
  "tabler-file-time",
  "tabler-file-type-bmp",
  "tabler-file-type-css",
  "tabler-file-type-csv",
  "tabler-file-type-doc",
  "tabler-file-type-docx",
  "tabler-file-type-html",
  "tabler-file-type-jpg",
  "tabler-file-type-js",
  "tabler-file-type-jsx",
  "tabler-file-type-pdf",
  "tabler-file-type-php",
  "tabler-file-type-png",
  "tabler-file-type-ppt",
  "tabler-file-type-rs",
  "tabler-file-type-sql",
  "tabler-file-type-svg",
  "tabler-file-type-ts",
  "tabler-file-type-tsx",
  "tabler-file-type-txt",
  "tabler-file-type-vue",
  "tabler-file-type-xls",
  "tabler-file-type-xml",
  "tabler-file-type-zip",
  "tabler-file-typography",
  "tabler-file-unknown",
  "tabler-file-upload",
  "tabler-file-vector",
  "tabler-file-x-filled",
  "tabler-file-x",
  "tabler-file-zip",
  "tabler-file",
  "tabler-files-off",
  "tabler-files",
  "tabler-filter-bolt",
  "tabler-filter-cancel",
  "tabler-filter-check",
  "tabler-filter-code",
  "tabler-filter-cog",
  "tabler-filter-discount",
  "tabler-filter-dollar",
  "tabler-filter-down",
  "tabler-filter-edit",
  "tabler-filter-exclamation",
  "tabler-filter-filled",
  "tabler-filter-heart",
  "tabler-filter-minus",
  "tabler-filter-off",
  "tabler-filter-pause",
  "tabler-filter-pin",
  "tabler-filter-plus",
  "tabler-filter-question",
  "tabler-filter-search",
  "tabler-filter-share",
  "tabler-filter-star",
  "tabler-filter-up",
  "tabler-filter-x",
  "tabler-filter",
  "tabler-filters",
  "tabler-fingerprint-off",
  "tabler-fingerprint-scan",
  "tabler-fingerprint",
  "tabler-fire-extinguisher",
  "tabler-fire-hydrant-off",
  "tabler-fire-hydrant",
  "tabler-firetruck",
  "tabler-first-aid-kit-off",
  "tabler-first-aid-kit",
  "tabler-fish-bone",
  "tabler-fish-christianity",
  "tabler-fish-hook-off",
  "tabler-fish-hook",
  "tabler-fish-off",
  "tabler-fish",
  "tabler-flag-2-filled",
  "tabler-flag-2-off",
  "tabler-flag-2",
  "tabler-flag-3-filled",
  "tabler-flag-3",
  "tabler-flag-bolt",
  "tabler-flag-cancel",
  "tabler-flag-check",
  "tabler-flag-code",
  "tabler-flag-cog",
  "tabler-flag-discount",
  "tabler-flag-dollar",
  "tabler-flag-down",
  "tabler-flag-exclamation",
  "tabler-flag-filled",
  "tabler-flag-heart",
  "tabler-flag-minus",
  "tabler-flag-off",
  "tabler-flag-pause",
  "tabler-flag-pin",
  "tabler-flag-plus",
  "tabler-flag-question",
  "tabler-flag-search",
  "tabler-flag-share",
  "tabler-flag-star",
  "tabler-flag-up",
  "tabler-flag-x",
  "tabler-flag",
  "tabler-flame-off",
  "tabler-flame",
  "tabler-flare",
  "tabler-flask-2-filled",
  "tabler-flask-2-off",
  "tabler-flask-2",
  "tabler-flask-filled",
  "tabler-flask-off",
  "tabler-flask",
  "tabler-flip-flops",
  "tabler-flip-horizontal",
  "tabler-flip-vertical",
  "tabler-float-center",
  "tabler-float-left",
  "tabler-float-none",
  "tabler-float-right",
  "tabler-flower-off",
  "tabler-flower",
  "tabler-focus-2",
  "tabler-focus-auto",
  "tabler-focus-centered",
  "tabler-focus",
  "tabler-fold-down",
  "tabler-fold-up",
  "tabler-fold",
  "tabler-folder-bolt",
  "tabler-folder-cancel",
  "tabler-folder-check",
  "tabler-folder-code",
  "tabler-folder-cog",
  "tabler-folder-dollar",
  "tabler-folder-down",
  "tabler-folder-exclamation",
  "tabler-folder-filled",
  "tabler-folder-heart",
  "tabler-folder-minus",
  "tabler-folder-off",
  "tabler-folder-open",
  "tabler-folder-pause",
  "tabler-folder-pin",
  "tabler-folder-plus",
  "tabler-folder-question",
  "tabler-folder-search",
  "tabler-folder-share",
  "tabler-folder-star",
  "tabler-folder-symlink",
  "tabler-folder-up",
  "tabler-folder-x",
  "tabler-folder",
  "tabler-folders-off",
  "tabler-folders",
  "tabler-forbid-2-filled",
  "tabler-forbid-2",
  "tabler-forbid-filled",
  "tabler-forbid",
  "tabler-forklift",
  "tabler-forms",
  "tabler-fountain-filled",
  "tabler-fountain-off",
  "tabler-fountain",
  "tabler-frame-off",
  "tabler-frame",
  "tabler-free-rights",
  "tabler-freeze-column",
  "tabler-freeze-row-column",
  "tabler-freeze-row",
  "tabler-fridge-off",
  "tabler-fridge",
  "tabler-friends-off",
  "tabler-friends",
  "tabler-frustum-off",
  "tabler-frustum-plus",
  "tabler-frustum",
  "tabler-function-filled",
  "tabler-function-off",
  "tabler-function",
  "tabler-galaxy",
  "tabler-garden-cart-off",
  "tabler-garden-cart",
  "tabler-gas-station-off",
  "tabler-gas-station",
  "tabler-gauge-filled",
  "tabler-gauge-off",
  "tabler-gauge",
  "tabler-gavel",
  "tabler-gender-agender",
  "tabler-gender-androgyne",
  "tabler-gender-bigender",
  "tabler-gender-demiboy",
  "tabler-gender-demigirl",
  "tabler-gender-epicene",
  "tabler-gender-female",
  "tabler-gender-femme",
  "tabler-gender-genderfluid",
  "tabler-gender-genderless",
  "tabler-gender-genderqueer",
  "tabler-gender-hermaphrodite",
  "tabler-gender-intergender",
  "tabler-gender-male",
  "tabler-gender-neutrois",
  "tabler-gender-third",
  "tabler-gender-transgender",
  "tabler-gender-trasvesti",
  "tabler-geometry",
  "tabler-ghost-2-filled",
  "tabler-ghost-2",
  "tabler-ghost-3",
  "tabler-ghost-filled",
  "tabler-ghost-off",
  "tabler-ghost",
  "tabler-gif",
  "tabler-gift-card-filled",
  "tabler-gift-card",
  "tabler-gift-filled",
  "tabler-gift-off",
  "tabler-gift",
  "tabler-git-branch-deleted",
  "tabler-git-branch",
  "tabler-git-cherry-pick",
  "tabler-git-commit",
  "tabler-git-compare",
  "tabler-git-fork",
  "tabler-git-merge",
  "tabler-git-pull-request-closed",
  "tabler-git-pull-request-draft",
  "tabler-git-pull-request",
  "tabler-gizmo",
  "tabler-glass-full-filled",
  "tabler-glass-full",
  "tabler-glass-off",
  "tabler-glass",
  "tabler-globe-filled",
  "tabler-globe-off",
  "tabler-globe",
  "tabler-go-game",
  "tabler-golf-off",
  "tabler-golf",
  "tabler-gps",
  "tabler-gradienter",
  "tabler-grain",
  "tabler-graph-filled",
  "tabler-graph-off",
  "tabler-graph",
  "tabler-grave-2",
  "tabler-grave",
  "tabler-grid-3x3",
  "tabler-grid-4x4",
  "tabler-grid-dots",
  "tabler-grid-goldenratio",
  "tabler-grid-pattern",
  "tabler-grid-scan",
  "tabler-grill-fork",
  "tabler-grill-off",
  "tabler-grill-spatula",
  "tabler-grill",
  "tabler-grip-horizontal",
  "tabler-grip-vertical",
  "tabler-growth",
  "tabler-guitar-pick-filled",
  "tabler-guitar-pick",
  "tabler-h-1",
  "tabler-h-2",
  "tabler-h-3",
  "tabler-h-4",
  "tabler-h-5",
  "tabler-h-6",
  "tabler-hammer-off",
  "tabler-hammer",
  "tabler-hand-click",
  "tabler-hand-finger-off",
  "tabler-hand-finger",
  "tabler-hand-grab",
  "tabler-hand-little-finger",
  "tabler-hand-middle-finger",
  "tabler-hand-move",
  "tabler-hand-off",
  "tabler-hand-ring-finger",
  "tabler-hand-rock",
  "tabler-hand-sanitizer",
  "tabler-hand-stop",
  "tabler-hand-three-fingers",
  "tabler-hand-two-fingers",
  "tabler-hanger-2",
  "tabler-hanger-off",
  "tabler-hanger",
  "tabler-hash",
  "tabler-haze-moon",
  "tabler-haze",
  "tabler-hdr",
  "tabler-heading-off",
  "tabler-heading",
  "tabler-headphones-filled",
  "tabler-headphones-off",
  "tabler-headphones",
  "tabler-headset-off",
  "tabler-headset",
  "tabler-health-recognition",
  "tabler-heart-bolt",
  "tabler-heart-broken",
  "tabler-heart-cancel",
  "tabler-heart-check",
  "tabler-heart-code",
  "tabler-heart-cog",
  "tabler-heart-discount",
  "tabler-heart-dollar",
  "tabler-heart-down",
  "tabler-heart-exclamation",
  "tabler-heart-filled",
  "tabler-heart-handshake",
  "tabler-heart-minus",
  "tabler-heart-off",
  "tabler-heart-pause",
  "tabler-heart-pin",
  "tabler-heart-plus",
  "tabler-heart-question",
  "tabler-heart-rate-monitor",
  "tabler-heart-search",
  "tabler-heart-share",
  "tabler-heart-star",
  "tabler-heart-up",
  "tabler-heart-x",
  "tabler-heart",
  "tabler-heartbeat",
  "tabler-hearts-off",
  "tabler-hearts",
  "tabler-helicopter-landing",
  "tabler-helicopter",
  "tabler-helmet-off",
  "tabler-helmet",
  "tabler-help-circle-filled",
  "tabler-help-circle",
  "tabler-help-hexagon-filled",
  "tabler-help-hexagon",
  "tabler-help-octagon-filled",
  "tabler-help-octagon",
  "tabler-help-off",
  "tabler-help-small",
  "tabler-help-square-filled",
  "tabler-help-square-rounded-filled",
  "tabler-help-square-rounded",
  "tabler-help-square",
  "tabler-help-triangle-filled",
  "tabler-help-triangle",
  "tabler-help",
  "tabler-hemisphere-off",
  "tabler-hemisphere-plus",
  "tabler-hemisphere",
  "tabler-hexagon-0-filled",
  "tabler-hexagon-1-filled",
  "tabler-hexagon-2-filled",
  "tabler-hexagon-3-filled",
  "tabler-hexagon-3d",
  "tabler-hexagon-4-filled",
  "tabler-hexagon-5-filled",
  "tabler-hexagon-6-filled",
  "tabler-hexagon-7-filled",
  "tabler-hexagon-8-filled",
  "tabler-hexagon-9-filled",
  "tabler-hexagon-filled",
  "tabler-hexagon-letter-a",
  "tabler-hexagon-letter-b",
  "tabler-hexagon-letter-c",
  "tabler-hexagon-letter-d",
  "tabler-hexagon-letter-e",
  "tabler-hexagon-letter-f",
  "tabler-hexagon-letter-g",
  "tabler-hexagon-letter-h",
  "tabler-hexagon-letter-i",
  "tabler-hexagon-letter-j",
  "tabler-hexagon-letter-k",
  "tabler-hexagon-letter-l",
  "tabler-hexagon-letter-m",
  "tabler-hexagon-letter-n",
  "tabler-hexagon-letter-o",
  "tabler-hexagon-letter-p",
  "tabler-hexagon-letter-q",
  "tabler-hexagon-letter-r",
  "tabler-hexagon-letter-s",
  "tabler-hexagon-letter-t",
  "tabler-hexagon-letter-u",
  "tabler-hexagon-letter-v",
  "tabler-hexagon-letter-w",
  "tabler-hexagon-letter-x",
  "tabler-hexagon-letter-y",
  "tabler-hexagon-letter-z",
  "tabler-hexagon-minus-2",
  "tabler-hexagon-minus",
  "tabler-hexagon-number-0",
  "tabler-hexagon-number-1",
  "tabler-hexagon-number-2",
  "tabler-hexagon-number-3",
  "tabler-hexagon-number-4",
  "tabler-hexagon-number-5",
  "tabler-hexagon-number-6",
  "tabler-hexagon-number-7",
  "tabler-hexagon-number-8",
  "tabler-hexagon-number-9",
  "tabler-hexagon-off",
  "tabler-hexagon-plus-2",
  "tabler-hexagon-plus",
  "tabler-hexagon",
  "tabler-hexagonal-prism-off",
  "tabler-hexagonal-prism-plus",
  "tabler-hexagonal-prism",
  "tabler-hexagonal-pyramid-off",
  "tabler-hexagonal-pyramid-plus",
  "tabler-hexagonal-pyramid",
  "tabler-hexagons-off",
  "tabler-hexagons",
  "tabler-hierarchy-2",
  "tabler-hierarchy-3",
  "tabler-hierarchy-off",
  "tabler-hierarchy",
  "tabler-highlight-off",
  "tabler-highlight",
  "tabler-history-off",
  "tabler-history-toggle",
  "tabler-history",
  "tabler-home-2",
  "tabler-home-bolt",
  "tabler-home-cancel",
  "tabler-home-check",
  "tabler-home-cog",
  "tabler-home-dollar",
  "tabler-home-dot",
  "tabler-home-down",
  "tabler-home-eco",
  "tabler-home-edit",
  "tabler-home-exclamation",
  "tabler-home-hand",
  "tabler-home-heart",
  "tabler-home-infinity",
  "tabler-home-link",
  "tabler-home-minus",
  "tabler-home-move",
  "tabler-home-off",
  "tabler-home-plus",
  "tabler-home-question",
  "tabler-home-ribbon",
  "tabler-home-search",
  "tabler-home-share",
  "tabler-home-shield",
  "tabler-home-signal",
  "tabler-home-star",
  "tabler-home-stats",
  "tabler-home-up",
  "tabler-home-x",
  "tabler-home",
  "tabler-horse-toy",
  "tabler-horse",
  "tabler-horseshoe",
  "tabler-hotel-service",
  "tabler-hourglass-empty",
  "tabler-hourglass-filled",
  "tabler-hourglass-high",
  "tabler-hourglass-low",
  "tabler-hourglass-off",
  "tabler-hourglass",
  "tabler-html",
  "tabler-http-connect",
  "tabler-http-delete",
  "tabler-http-get",
  "tabler-http-head",
  "tabler-http-options",
  "tabler-http-patch",
  "tabler-http-post",
  "tabler-http-put",
  "tabler-http-que",
  "tabler-http-trace",
  "tabler-ice-cream-2",
  "tabler-ice-cream-off",
  "tabler-ice-cream",
  "tabler-ice-skating",
  "tabler-icons-off",
  "tabler-icons",
  "tabler-id-badge-2",
  "tabler-id-badge-off",
  "tabler-id-badge",
  "tabler-id-off",
  "tabler-id",
  "tabler-inbox-off",
  "tabler-inbox",
  "tabler-indent-decrease",
  "tabler-indent-increase",
  "tabler-infinity-off",
  "tabler-infinity",
  "tabler-info-circle-filled",
  "tabler-info-circle",
  "tabler-info-hexagon-filled",
  "tabler-info-hexagon",
  "tabler-info-octagon-filled",
  "tabler-info-octagon",
  "tabler-info-small",
  "tabler-info-square-filled",
  "tabler-info-square-rounded-filled",
  "tabler-info-square-rounded",
  "tabler-info-square",
  "tabler-info-triangle-filled",
  "tabler-info-triangle",
  "tabler-inner-shadow-bottom-filled",
  "tabler-inner-shadow-bottom-left-filled",
  "tabler-inner-shadow-bottom-left",
  "tabler-inner-shadow-bottom-right-filled",
  "tabler-inner-shadow-bottom-right",
  "tabler-inner-shadow-bottom",
  "tabler-inner-shadow-left-filled",
  "tabler-inner-shadow-left",
  "tabler-inner-shadow-right-filled",
  "tabler-inner-shadow-right",
  "tabler-inner-shadow-top-filled",
  "tabler-inner-shadow-top-left-filled",
  "tabler-inner-shadow-top-left",
  "tabler-inner-shadow-top-right-filled",
  "tabler-inner-shadow-top-right",
  "tabler-inner-shadow-top",
  "tabler-input-ai",
  "tabler-input-check",
  "tabler-input-search",
  "tabler-input-x",
  "tabler-ironing-1",
  "tabler-ironing-2",
  "tabler-ironing-3",
  "tabler-ironing-off",
  "tabler-ironing-steam-off",
  "tabler-ironing-steam",
  "tabler-ironing",
  "tabler-irregular-polyhedron-off",
  "tabler-irregular-polyhedron-plus",
  "tabler-irregular-polyhedron",
  "tabler-italic",
  "tabler-jacket",
  "tabler-jetpack",
  "tabler-jewish-star-filled",
  "tabler-jewish-star",
  "tabler-jpg",
  "tabler-json",
  "tabler-jump-rope",
  "tabler-karate",
  "tabler-kayak",
  "tabler-kering",
  "tabler-key-off",
  "tabler-key",
  "tabler-keyboard-hide",
  "tabler-keyboard-off",
  "tabler-keyboard-show",
  "tabler-keyboard",
  "tabler-keyframe-align-center-filled",
  "tabler-keyframe-align-center",
  "tabler-keyframe-align-horizontal-filled",
  "tabler-keyframe-align-horizontal",
  "tabler-keyframe-align-vertical-filled",
  "tabler-keyframe-align-vertical",
  "tabler-keyframe-filled",
  "tabler-keyframe",
  "tabler-keyframes-filled",
  "tabler-keyframes",
  "tabler-ladder-off",
  "tabler-ladder",
  "tabler-ladle",
  "tabler-lambda",
  "tabler-lamp-2",
  "tabler-lamp-off",
  "tabler-lamp",
  "tabler-lane",
  "tabler-language-hiragana",
  "tabler-language-katakana",
  "tabler-language-off",
  "tabler-language",
  "tabler-lasso-off",
  "tabler-lasso-polygon",
  "tabler-lasso",
  "tabler-layers-difference",
  "tabler-layers-intersect-2",
  "tabler-layers-intersect",
  "tabler-layers-linked",
  "tabler-layers-off",
  "tabler-layers-subtract",
  "tabler-layers-union",
  "tabler-layout-2",
  "tabler-layout-align-bottom",
  "tabler-layout-align-center",
  "tabler-layout-align-left",
  "tabler-layout-align-middle",
  "tabler-layout-align-right",
  "tabler-layout-align-top",
  "tabler-layout-board-split",
  "tabler-layout-board",
  "tabler-layout-bottombar-collapse-filled",
  "tabler-layout-bottombar-collapse",
  "tabler-layout-bottombar-expand-filled",
  "tabler-layout-bottombar-expand",
  "tabler-layout-bottombar-filled",
  "tabler-layout-bottombar",
  "tabler-layout-cards",
  "tabler-layout-collage",
  "tabler-layout-columns",
  "tabler-layout-dashboard",
  "tabler-layout-distribute-horizontal",
  "tabler-layout-distribute-vertical",
  "tabler-layout-grid-add",
  "tabler-layout-grid-remove",
  "tabler-layout-grid",
  "tabler-layout-kanban",
  "tabler-layout-list",
  "tabler-layout-navbar-collapse-filled",
  "tabler-layout-navbar-collapse",
  "tabler-layout-navbar-expand-filled",
  "tabler-layout-navbar-expand",
  "tabler-layout-navbar-filled",
  "tabler-layout-navbar",
  "tabler-layout-off",
  "tabler-layout-rows",
  "tabler-layout-sidebar-left-collapse-filled",
  "tabler-layout-sidebar-left-collapse",
  "tabler-layout-sidebar-left-expand-filled",
  "tabler-layout-sidebar-left-expand",
  "tabler-layout-sidebar-right-collapse-filled",
  "tabler-layout-sidebar-right-collapse",
  "tabler-layout-sidebar-right-expand-filled",
  "tabler-layout-sidebar-right-expand",
  "tabler-layout-sidebar-right",
  "tabler-layout-sidebar",
  "tabler-layout",
  "tabler-leaf-off",
  "tabler-leaf",
  "tabler-lego-off",
  "tabler-lego",
  "tabler-lemon-2",
  "tabler-lemon",
  "tabler-letter-a-small",
  "tabler-letter-a",
  "tabler-letter-b-small",
  "tabler-letter-b",
  "tabler-letter-c-small",
  "tabler-letter-c",
  "tabler-letter-case-lower",
  "tabler-letter-case-toggle",
  "tabler-letter-case-upper",
  "tabler-letter-case",
  "tabler-letter-d-small",
  "tabler-letter-d",
  "tabler-letter-e-small",
  "tabler-letter-e",
  "tabler-letter-f-small",
  "tabler-letter-f",
  "tabler-letter-g-small",
  "tabler-letter-g",
  "tabler-letter-h-small",
  "tabler-letter-h",
  "tabler-letter-i-small",
  "tabler-letter-i",
  "tabler-letter-j-small",
  "tabler-letter-j",
  "tabler-letter-k-small",
  "tabler-letter-k",
  "tabler-letter-l-small",
  "tabler-letter-l",
  "tabler-letter-m-small",
  "tabler-letter-m",
  "tabler-letter-n-small",
  "tabler-letter-n",
  "tabler-letter-o-small",
  "tabler-letter-o",
  "tabler-letter-p-small",
  "tabler-letter-p",
  "tabler-letter-q-small",
  "tabler-letter-q",
  "tabler-letter-r-small",
  "tabler-letter-r",
  "tabler-letter-s-small",
  "tabler-letter-s",
  "tabler-letter-spacing",
  "tabler-letter-t-small",
  "tabler-letter-t",
  "tabler-letter-u-small",
  "tabler-letter-u",
  "tabler-letter-v-small",
  "tabler-letter-v",
  "tabler-letter-w-small",
  "tabler-letter-w",
  "tabler-letter-x-small",
  "tabler-letter-x",
  "tabler-letter-y-small",
  "tabler-letter-y",
  "tabler-letter-z-small",
  "tabler-letter-z",
  "tabler-license-off",
  "tabler-license",
  "tabler-lifebuoy-off",
  "tabler-lifebuoy",
  "tabler-lighter",
  "tabler-line-dashed",
  "tabler-line-dotted",
  "tabler-line-height",
  "tabler-line-scan",
  "tabler-line",
  "tabler-link-minus",
  "tabler-link-off",
  "tabler-link-plus",
  "tabler-link",
  "tabler-list-check",
  "tabler-list-details",
  "tabler-list-letters",
  "tabler-list-numbers",
  "tabler-list-search",
  "tabler-list-tree",
  "tabler-list",
  "tabler-live-photo-off",
  "tabler-live-photo",
  "tabler-live-view",
  "tabler-load-balancer",
  "tabler-loader-2",
  "tabler-loader-3",
  "tabler-loader-quarter",
  "tabler-loader",
  "tabler-location-bolt",
  "tabler-location-broken",
  "tabler-location-cancel",
  "tabler-location-check",
  "tabler-location-code",
  "tabler-location-cog",
  "tabler-location-discount",
  "tabler-location-dollar",
  "tabler-location-down",
  "tabler-location-exclamation",
  "tabler-location-filled",
  "tabler-location-heart",
  "tabler-location-minus",
  "tabler-location-off",
  "tabler-location-pause",
  "tabler-location-pin",
  "tabler-location-plus",
  "tabler-location-question",
  "tabler-location-search",
  "tabler-location-share",
  "tabler-location-star",
  "tabler-location-up",
  "tabler-location-x",
  "tabler-location",
  "tabler-lock-access-off",
  "tabler-lock-access",
  "tabler-lock-bolt",
  "tabler-lock-cancel",
  "tabler-lock-check",
  "tabler-lock-code",
  "tabler-lock-cog",
  "tabler-lock-dollar",
  "tabler-lock-down",
  "tabler-lock-exclamation",
  "tabler-lock-heart",
  "tabler-lock-minus",
  "tabler-lock-off",
  "tabler-lock-open-off",
  "tabler-lock-open",
  "tabler-lock-pause",
  "tabler-lock-pin",
  "tabler-lock-plus",
  "tabler-lock-question",
  "tabler-lock-search",
  "tabler-lock-share",
  "tabler-lock-square-rounded-filled",
  "tabler-lock-square-rounded",
  "tabler-lock-square",
  "tabler-lock-star",
  "tabler-lock-up",
  "tabler-lock-x",
  "tabler-lock",
  "tabler-logic-and",
  "tabler-logic-buffer",
  "tabler-logic-nand",
  "tabler-logic-nor",
  "tabler-logic-not",
  "tabler-logic-or",
  "tabler-logic-xnor",
  "tabler-logic-xor",
  "tabler-login-2",
  "tabler-login",
  "tabler-logout-2",
  "tabler-logout",
  "tabler-lollipop-off",
  "tabler-lollipop",
  "tabler-luggage-off",
  "tabler-luggage",
  "tabler-lungs-off",
  "tabler-lungs",
  "tabler-macro-off",
  "tabler-macro",
  "tabler-magnet-off",
  "tabler-magnet",
  "tabler-magnetic",
  "tabler-mail-ai",
  "tabler-mail-bolt",
  "tabler-mail-cancel",
  "tabler-mail-check",
  "tabler-mail-code",
  "tabler-mail-cog",
  "tabler-mail-dollar",
  "tabler-mail-down",
  "tabler-mail-exclamation",
  "tabler-mail-fast",
  "tabler-mail-filled",
  "tabler-mail-forward",
  "tabler-mail-heart",
  "tabler-mail-minus",
  "tabler-mail-off",
  "tabler-mail-opened-filled",
  "tabler-mail-opened",
  "tabler-mail-pause",
  "tabler-mail-pin",
  "tabler-mail-plus",
  "tabler-mail-question",
  "tabler-mail-search",
  "tabler-mail-share",
  "tabler-mail-star",
  "tabler-mail-up",
  "tabler-mail-x",
  "tabler-mail",
  "tabler-mailbox-off",
  "tabler-mailbox",
  "tabler-man",
  "tabler-manual-gearbox",
  "tabler-map-2",
  "tabler-map-bolt",
  "tabler-map-cancel",
  "tabler-map-check",
  "tabler-map-code",
  "tabler-map-cog",
  "tabler-map-discount",
  "tabler-map-dollar",
  "tabler-map-down",
  "tabler-map-east",
  "tabler-map-exclamation",
  "tabler-map-heart",
  "tabler-map-minus",
  "tabler-map-north",
  "tabler-map-off",
  "tabler-map-pause",
  "tabler-map-pin-2",
  "tabler-map-pin-bolt",
  "tabler-map-pin-cancel",
  "tabler-map-pin-check",
  "tabler-map-pin-code",
  "tabler-map-pin-cog",
  "tabler-map-pin-dollar",
  "tabler-map-pin-down",
  "tabler-map-pin-exclamation",
  "tabler-map-pin-filled",
  "tabler-map-pin-heart",
  "tabler-map-pin-minus",
  "tabler-map-pin-off",
  "tabler-map-pin-pause",
  "tabler-map-pin-pin",
  "tabler-map-pin-plus",
  "tabler-map-pin-question",
  "tabler-map-pin-search",
  "tabler-map-pin-share",
  "tabler-map-pin-star",
  "tabler-map-pin-up",
  "tabler-map-pin-x",
  "tabler-map-pin",
  "tabler-map-pins",
  "tabler-map-plus",
  "tabler-map-question",
  "tabler-map-route",
  "tabler-map-search",
  "tabler-map-share",
  "tabler-map-south",
  "tabler-map-star",
  "tabler-map-up",
  "tabler-map-west",
  "tabler-map-x",
  "tabler-map",
  "tabler-markdown-off",
  "tabler-markdown",
  "tabler-marquee-2",
  "tabler-marquee-off",
  "tabler-marquee",
  "tabler-mars",
  "tabler-mask-off",
  "tabler-mask",
  "tabler-masks-theater-off",
  "tabler-masks-theater",
  "tabler-massage",
  "tabler-matchstick",
  "tabler-math-1-divide-2",
  "tabler-math-1-divide-3",
  "tabler-math-avg",
  "tabler-math-equal-greater",
  "tabler-math-equal-lower",
  "tabler-math-function-off",
  "tabler-math-function-y",
  "tabler-math-function",
  "tabler-math-greater",
  "tabler-math-integral-x",
  "tabler-math-integral",
  "tabler-math-integrals",
  "tabler-math-lower",
  "tabler-math-max",
  "tabler-math-min",
  "tabler-math-not",
  "tabler-math-off",
  "tabler-math-pi-divide-2",
  "tabler-math-pi",
  "tabler-math-symbols",
  "tabler-math-x-divide-2",
  "tabler-math-x-divide-y-2",
  "tabler-math-x-divide-y",
  "tabler-math-x-minus-x",
  "tabler-math-x-minus-y",
  "tabler-math-x-plus-x",
  "tabler-math-x-plus-y",
  "tabler-math-xy",
  "tabler-math-y-minus-y",
  "tabler-math-y-plus-y",
  "tabler-math",
  "tabler-maximize-off",
  "tabler-maximize",
  "tabler-meat-off",
  "tabler-meat",
  "tabler-medal-2",
  "tabler-medal",
  "tabler-medical-cross-circle",
  "tabler-medical-cross-filled",
  "tabler-medical-cross-off",
  "tabler-medical-cross",
  "tabler-medicine-syrup",
  "tabler-meeple",
  "tabler-melon",
  "tabler-menorah",
  "tabler-menu-2",
  "tabler-menu-deep",
  "tabler-menu-order",
  "tabler-menu",
  "tabler-message-2-bolt",
  "tabler-message-2-cancel",
  "tabler-message-2-check",
  "tabler-message-2-code",
  "tabler-message-2-cog",
  "tabler-message-2-dollar",
  "tabler-message-2-down",
  "tabler-message-2-exclamation",
  "tabler-message-2-heart",
  "tabler-message-2-minus",
  "tabler-message-2-off",
  "tabler-message-2-pause",
  "tabler-message-2-pin",
  "tabler-message-2-plus",
  "tabler-message-2-question",
  "tabler-message-2-search",
  "tabler-message-2-share",
  "tabler-message-2-star",
  "tabler-message-2-up",
  "tabler-message-2-x",
  "tabler-message-2",
  "tabler-message-bolt",
  "tabler-message-cancel",
  "tabler-message-chatbot",
  "tabler-message-check",
  "tabler-message-circle-2-filled",
  "tabler-message-circle-2",
  "tabler-message-circle-bolt",
  "tabler-message-circle-cancel",
  "tabler-message-circle-check",
  "tabler-message-circle-code",
  "tabler-message-circle-cog",
  "tabler-message-circle-dollar",
  "tabler-message-circle-down",
  "tabler-message-circle-exclamation",
  "tabler-message-circle-heart",
  "tabler-message-circle-minus",
  "tabler-message-circle-off",
  "tabler-message-circle-pause",
  "tabler-message-circle-pin",
  "tabler-message-circle-plus",
  "tabler-message-circle-question",
  "tabler-message-circle-search",
  "tabler-message-circle-share",
  "tabler-message-circle-star",
  "tabler-message-circle-up",
  "tabler-message-circle-x",
  "tabler-message-circle",
  "tabler-message-code",
  "tabler-message-cog",
  "tabler-message-dollar",
  "tabler-message-dots",
  "tabler-message-down",
  "tabler-message-exclamation",
  "tabler-message-forward",
  "tabler-message-heart",
  "tabler-message-language",
  "tabler-message-minus",
  "tabler-message-off",
  "tabler-message-pause",
  "tabler-message-pin",
  "tabler-message-plus",
  "tabler-message-question",
  "tabler-message-report",
  "tabler-message-search",
  "tabler-message-share",
  "tabler-message-star",
  "tabler-message-up",
  "tabler-message-x",
  "tabler-message",
  "tabler-messages-off",
  "tabler-messages",
  "tabler-meteor-off",
  "tabler-meteor",
  "tabler-michelin-bib-gourmand",
  "tabler-michelin-star-green",
  "tabler-michelin-star",
  "tabler-mickey-filled",
  "tabler-mickey",
  "tabler-microphone-2-off",
  "tabler-microphone-2",
  "tabler-microphone-off",
  "tabler-microphone",
  "tabler-microscope-off",
  "tabler-microscope",
  "tabler-microwave-off",
  "tabler-microwave",
  "tabler-military-award",
  "tabler-military-rank",
  "tabler-milk-off",
  "tabler-milk",
  "tabler-milkshake",
  "tabler-minimize",
  "tabler-minus-vertical",
  "tabler-minus",
  "tabler-mist-off",
  "tabler-mist",
  "tabler-mobiledata-off",
  "tabler-mobiledata",
  "tabler-moneybag",
  "tabler-mood-angry",
  "tabler-mood-annoyed-2",
  "tabler-mood-annoyed",
  "tabler-mood-boy",
  "tabler-mood-check",
  "tabler-mood-cog",
  "tabler-mood-confuzed-filled",
  "tabler-mood-confuzed",
  "tabler-mood-crazy-happy",
  "tabler-mood-cry",
  "tabler-mood-dollar",
  "tabler-mood-edit",
  "tabler-mood-empty-filled",
  "tabler-mood-empty",
  "tabler-mood-happy-filled",
  "tabler-mood-happy",
  "tabler-mood-heart",
  "tabler-mood-kid-filled",
  "tabler-mood-kid",
  "tabler-mood-look-left",
  "tabler-mood-look-right",
  "tabler-mood-minus",
  "tabler-mood-nerd",
  "tabler-mood-nervous",
  "tabler-mood-neutral-filled",
  "tabler-mood-neutral",
  "tabler-mood-off",
  "tabler-mood-pin",
  "tabler-mood-plus",
  "tabler-mood-sad-2",
  "tabler-mood-sad-dizzy",
  "tabler-mood-sad-filled",
  "tabler-mood-sad-squint",
  "tabler-mood-sad",
  "tabler-mood-search",
  "tabler-mood-share",
  "tabler-mood-sick",
  "tabler-mood-silence",
  "tabler-mood-sing",
  "tabler-mood-smile-beam",
  "tabler-mood-smile-dizzy",
  "tabler-mood-smile-filled",
  "tabler-mood-smile",
  "tabler-mood-suprised",
  "tabler-mood-tongue-wink-2",
  "tabler-mood-tongue-wink",
  "tabler-mood-tongue",
  "tabler-mood-unamused",
  "tabler-mood-up",
  "tabler-mood-wink-2",
  "tabler-mood-wink",
  "tabler-mood-wrrr",
  "tabler-mood-x",
  "tabler-mood-xd",
  "tabler-moon-2",
  "tabler-moon-filled",
  "tabler-moon-off",
  "tabler-moon-stars",
  "tabler-moon",
  "tabler-moped",
  "tabler-motorbike",
  "tabler-mountain-off",
  "tabler-mountain",
  "tabler-mouse-2",
  "tabler-mouse-filled",
  "tabler-mouse-off",
  "tabler-mouse",
  "tabler-moustache",
  "tabler-movie-off",
  "tabler-movie",
  "tabler-mug-off",
  "tabler-mug",
  "tabler-multiplier-0-5x",
  "tabler-multiplier-1-5x",
  "tabler-multiplier-1x",
  "tabler-multiplier-2x",
  "tabler-mushroom-filled",
  "tabler-mushroom-off",
  "tabler-mushroom",
  "tabler-music-bolt",
  "tabler-music-cancel",
  "tabler-music-check",
  "tabler-music-code",
  "tabler-music-cog",
  "tabler-music-discount",
  "tabler-music-dollar",
  "tabler-music-down",
  "tabler-music-exclamation",
  "tabler-music-heart",
  "tabler-music-minus",
  "tabler-music-off",
  "tabler-music-pause",
  "tabler-music-pin",
  "tabler-music-plus",
  "tabler-music-question",
  "tabler-music-search",
  "tabler-music-share",
  "tabler-music-star",
  "tabler-music-up",
  "tabler-music-x",
  "tabler-music",
  "tabler-navigation-bolt",
  "tabler-navigation-cancel",
  "tabler-navigation-check",
  "tabler-navigation-code",
  "tabler-navigation-cog",
  "tabler-navigation-discount",
  "tabler-navigation-dollar",
  "tabler-navigation-down",
  "tabler-navigation-east",
  "tabler-navigation-exclamation",
  "tabler-navigation-filled",
  "tabler-navigation-heart",
  "tabler-navigation-minus",
  "tabler-navigation-north",
  "tabler-navigation-off",
  "tabler-navigation-pause",
  "tabler-navigation-pin",
  "tabler-navigation-plus",
  "tabler-navigation-question",
  "tabler-navigation-search",
  "tabler-navigation-share",
  "tabler-navigation-south",
  "tabler-navigation-star",
  "tabler-navigation-top",
  "tabler-navigation-up",
  "tabler-navigation-west",
  "tabler-navigation-x",
  "tabler-navigation",
  "tabler-needle-thread",
  "tabler-needle",
  "tabler-network-off",
  "tabler-network",
  "tabler-new-section",
  "tabler-news-off",
  "tabler-news",
  "tabler-nfc-off",
  "tabler-nfc",
  "tabler-no-copyright",
  "tabler-no-creative-commons",
  "tabler-no-derivatives",
  "tabler-north-star",
  "tabler-note-off",
  "tabler-note",
  "tabler-notebook-off",
  "tabler-notebook",
  "tabler-notes-off",
  "tabler-notes",
  "tabler-notification-off",
  "tabler-notification",
  "tabler-number-0-small",
  "tabler-number-0",
  "tabler-number-1-small",
  "tabler-number-1",
  "tabler-number-10-small",
  "tabler-number-11-small",
  "tabler-number-12-small",
  "tabler-number-13-small",
  "tabler-number-14-small",
  "tabler-number-15-small",
  "tabler-number-16-small",
  "tabler-number-17-small",
  "tabler-number-18-small",
  "tabler-number-19-small",
  "tabler-number-2-small",
  "tabler-number-2",
  "tabler-number-20-small",
  "tabler-number-21-small",
  "tabler-number-22-small",
  "tabler-number-23-small",
  "tabler-number-24-small",
  "tabler-number-25-small",
  "tabler-number-26-small",
  "tabler-number-27-small",
  "tabler-number-28-small",
  "tabler-number-29-small",
  "tabler-number-3-small",
  "tabler-number-3",
  "tabler-number-4-small",
  "tabler-number-4",
  "tabler-number-5-small",
  "tabler-number-5",
  "tabler-number-6-small",
  "tabler-number-6",
  "tabler-number-7-small",
  "tabler-number-7",
  "tabler-number-8-small",
  "tabler-number-8",
  "tabler-number-9-small",
  "tabler-number-9",
  "tabler-number",
  "tabler-numbers",
  "tabler-nurse",
  "tabler-nut",
  "tabler-octagon-filled",
  "tabler-octagon-minus-2",
  "tabler-octagon-minus",
  "tabler-octagon-off",
  "tabler-octagon-plus-2",
  "tabler-octagon-plus",
  "tabler-octagon",
  "tabler-octahedron-off",
  "tabler-octahedron-plus",
  "tabler-octahedron",
  "tabler-old",
  "tabler-olympics-off",
  "tabler-olympics",
  "tabler-om",
  "tabler-omega",
  "tabler-outbound",
  "tabler-outlet",
  "tabler-oval-filled",
  "tabler-oval-vertical-filled",
  "tabler-oval-vertical",
  "tabler-oval",
  "tabler-overline",
  "tabler-package-export",
  "tabler-package-import",
  "tabler-package-off",
  "tabler-package",
  "tabler-packages",
  "tabler-pacman",
  "tabler-page-break",
  "tabler-paint-filled",
  "tabler-paint-off",
  "tabler-paint",
  "tabler-palette-off",
  "tabler-palette",
  "tabler-panorama-horizontal-off",
  "tabler-panorama-horizontal",
  "tabler-panorama-vertical-off",
  "tabler-panorama-vertical",
  "tabler-paper-bag-off",
  "tabler-paper-bag",
  "tabler-paperclip",
  "tabler-parachute-off",
  "tabler-parachute",
  "tabler-parentheses-off",
  "tabler-parentheses",
  "tabler-parking-off",
  "tabler-parking",
  "tabler-password-fingerprint",
  "tabler-password-mobile-phone",
  "tabler-password-user",
  "tabler-password",
  "tabler-paw-filled",
  "tabler-paw-off",
  "tabler-paw",
  "tabler-pdf",
  "tabler-peace",
  "tabler-pencil-bolt",
  "tabler-pencil-cancel",
  "tabler-pencil-check",
  "tabler-pencil-code",
  "tabler-pencil-cog",
  "tabler-pencil-discount",
  "tabler-pencil-dollar",
  "tabler-pencil-down",
  "tabler-pencil-exclamation",
  "tabler-pencil-heart",
  "tabler-pencil-minus",
  "tabler-pencil-off",
  "tabler-pencil-pause",
  "tabler-pencil-pin",
  "tabler-pencil-plus",
  "tabler-pencil-question",
  "tabler-pencil-search",
  "tabler-pencil-share",
  "tabler-pencil-star",
  "tabler-pencil-up",
  "tabler-pencil-x",
  "tabler-pencil",
  "tabler-pennant-2-filled",
  "tabler-pennant-2",
  "tabler-pennant-filled",
  "tabler-pennant-off",
  "tabler-pennant",
  "tabler-pentagon-filled",
  "tabler-pentagon-number-0",
  "tabler-pentagon-number-1",
  "tabler-pentagon-number-2",
  "tabler-pentagon-number-3",
  "tabler-pentagon-number-4",
  "tabler-pentagon-number-5",
  "tabler-pentagon-number-6",
  "tabler-pentagon-number-7",
  "tabler-pentagon-number-8",
  "tabler-pentagon-number-9",
  "tabler-pentagon-off",
  "tabler-pentagon-plus",
  "tabler-pentagon-x",
  "tabler-pentagon",
  "tabler-pentagram",
  "tabler-pepper-off",
  "tabler-pepper",
  "tabler-percentage",
  "tabler-perfume",
  "tabler-perspective-off",
  "tabler-perspective",
  "tabler-phone-call",
  "tabler-phone-calling",
  "tabler-phone-check",
  "tabler-phone-filled",
  "tabler-phone-incoming",
  "tabler-phone-off",
  "tabler-phone-outgoing",
  "tabler-phone-pause",
  "tabler-phone-plus",
  "tabler-phone-x",
  "tabler-phone",
  "tabler-photo-ai",
  "tabler-photo-bolt",
  "tabler-photo-cancel",
  "tabler-photo-check",
  "tabler-photo-circle-minus",
  "tabler-photo-circle-plus",
  "tabler-photo-circle",
  "tabler-photo-code",
  "tabler-photo-cog",
  "tabler-photo-dollar",
  "tabler-photo-down",
  "tabler-photo-edit",
  "tabler-photo-exclamation",
  "tabler-photo-filled",
  "tabler-photo-heart",
  "tabler-photo-hexagon",
  "tabler-photo-minus",
  "tabler-photo-off",
  "tabler-photo-pause",
  "tabler-photo-pentagon",
  "tabler-photo-pin",
  "tabler-photo-plus",
  "tabler-photo-question",
  "tabler-photo-scan",
  "tabler-photo-search",
  "tabler-photo-sensor-2",
  "tabler-photo-sensor-3",
  "tabler-photo-sensor",
  "tabler-photo-share",
  "tabler-photo-shield",
  "tabler-photo-square-rounded",
  "tabler-photo-star",
  "tabler-photo-up",
  "tabler-photo-video",
  "tabler-photo-x",
  "tabler-photo",
  "tabler-physotherapist",
  "tabler-piano",
  "tabler-pick",
  "tabler-picture-in-picture-off",
  "tabler-picture-in-picture-on",
  "tabler-picture-in-picture-top",
  "tabler-picture-in-picture",
  "tabler-pig-money",
  "tabler-pig-off",
  "tabler-pig",
  "tabler-pilcrow",
  "tabler-pill-off",
  "tabler-pill",
  "tabler-pills",
  "tabler-pin-filled",
  "tabler-pin",
  "tabler-ping-pong",
  "tabler-pinned-filled",
  "tabler-pinned-off",
  "tabler-pinned",
  "tabler-pizza-off",
  "tabler-pizza",
  "tabler-placeholder",
  "tabler-plane-arrival",
  "tabler-plane-departure",
  "tabler-plane-inflight",
  "tabler-plane-off",
  "tabler-plane-tilt",
  "tabler-plane",
  "tabler-planet-off",
  "tabler-planet",
  "tabler-plant-2-off",
  "tabler-plant-2",
  "tabler-plant-off",
  "tabler-plant",
  "tabler-play-basketball",
  "tabler-play-card-off",
  "tabler-play-card",
  "tabler-play-football",
  "tabler-play-handball",
  "tabler-play-volleyball",
  "tabler-player-eject-filled",
  "tabler-player-eject",
  "tabler-player-pause-filled",
  "tabler-player-pause",
  "tabler-player-play-filled",
  "tabler-player-play",
  "tabler-player-record-filled",
  "tabler-player-record",
  "tabler-player-skip-back-filled",
  "tabler-player-skip-back",
  "tabler-player-skip-forward-filled",
  "tabler-player-skip-forward",
  "tabler-player-stop-filled",
  "tabler-player-stop",
  "tabler-player-track-next-filled",
  "tabler-player-track-next",
  "tabler-player-track-prev-filled",
  "tabler-player-track-prev",
  "tabler-playlist-add",
  "tabler-playlist-off",
  "tabler-playlist-x",
  "tabler-playlist",
  "tabler-playstation-circle",
  "tabler-playstation-square",
  "tabler-playstation-triangle",
  "tabler-playstation-x",
  "tabler-plug-connected-x",
  "tabler-plug-connected",
  "tabler-plug-off",
  "tabler-plug-x",
  "tabler-plug",
  "tabler-plus-equal",
  "tabler-plus-minus",
  "tabler-plus",
  "tabler-png",
  "tabler-podium-off",
  "tabler-podium",
  "tabler-point-filled",
  "tabler-point-off",
  "tabler-point",
  "tabler-pointer-bolt",
  "tabler-pointer-cancel",
  "tabler-pointer-check",
  "tabler-pointer-code",
  "tabler-pointer-cog",
  "tabler-pointer-dollar",
  "tabler-pointer-down",
  "tabler-pointer-exclamation",
  "tabler-pointer-filled",
  "tabler-pointer-heart",
  "tabler-pointer-minus",
  "tabler-pointer-off",
  "tabler-pointer-pause",
  "tabler-pointer-pin",
  "tabler-pointer-plus",
  "tabler-pointer-question",
  "tabler-pointer-search",
  "tabler-pointer-share",
  "tabler-pointer-star",
  "tabler-pointer-up",
  "tabler-pointer-x",
  "tabler-pointer",
  "tabler-pokeball-off",
  "tabler-pokeball",
  "tabler-poker-chip",
  "tabler-polaroid-filled",
  "tabler-polaroid",
  "tabler-polygon-off",
  "tabler-polygon",
  "tabler-poo",
  "tabler-pool-off",
  "tabler-pool",
  "tabler-power",
  "tabler-pray",
  "tabler-premium-rights",
  "tabler-prescription",
  "tabler-presentation-analytics",
  "tabler-presentation-off",
  "tabler-presentation",
  "tabler-printer-off",
  "tabler-printer",
  "tabler-prism-off",
  "tabler-prism-plus",
  "tabler-prism",
  "tabler-prison",
  "tabler-progress-alert",
  "tabler-progress-bolt",
  "tabler-progress-check",
  "tabler-progress-down",
  "tabler-progress-help",
  "tabler-progress-x",
  "tabler-progress",
  "tabler-prompt",
  "tabler-propeller-off",
  "tabler-propeller",
  "tabler-pumpkin-scary",
  "tabler-puzzle-2",
  "tabler-puzzle-filled",
  "tabler-puzzle-off",
  "tabler-puzzle",
  "tabler-pyramid-off",
  "tabler-pyramid-plus",
  "tabler-pyramid",
  "tabler-qrcode-off",
  "tabler-qrcode",
  "tabler-question-mark",
  "tabler-quote-off",
  "tabler-quote",
  "tabler-quotes",
  "tabler-radar-2",
  "tabler-radar-off",
  "tabler-radar",
  "tabler-radio-off",
  "tabler-radio",
  "tabler-radioactive-filled",
  "tabler-radioactive-off",
  "tabler-radioactive",
  "tabler-radius-bottom-left",
  "tabler-radius-bottom-right",
  "tabler-radius-top-left",
  "tabler-radius-top-right",
  "tabler-rainbow-off",
  "tabler-rainbow",
  "tabler-rating-12-plus",
  "tabler-rating-14-plus",
  "tabler-rating-16-plus",
  "tabler-rating-18-plus",
  "tabler-rating-21-plus",
  "tabler-razor-electric",
  "tabler-razor",
  "tabler-receipt-2",
  "tabler-receipt-off",
  "tabler-receipt-refund",
  "tabler-receipt-tax",
  "tabler-receipt",
  "tabler-recharging",
  "tabler-record-mail-off",
  "tabler-record-mail",
  "tabler-rectangle-filled",
  "tabler-rectangle-rounded-bottom",
  "tabler-rectangle-rounded-top",
  "tabler-rectangle-vertical-filled",
  "tabler-rectangle-vertical",
  "tabler-rectangle",
  "tabler-rectangular-prism-off",
  "tabler-rectangular-prism-plus",
  "tabler-rectangular-prism",
  "tabler-recycle-off",
  "tabler-recycle",
  "tabler-refresh-alert",
  "tabler-refresh-dot",
  "tabler-refresh-off",
  "tabler-refresh",
  "tabler-regex-off",
  "tabler-regex",
  "tabler-registered",
  "tabler-relation-many-to-many",
  "tabler-relation-one-to-many",
  "tabler-relation-one-to-one",
  "tabler-reload",
  "tabler-reorder",
  "tabler-repeat-off",
  "tabler-repeat-once",
  "tabler-repeat",
  "tabler-replace-filled",
  "tabler-replace-off",
  "tabler-replace",
  "tabler-report-analytics",
  "tabler-report-medical",
  "tabler-report-money",
  "tabler-report-off",
  "tabler-report-search",
  "tabler-report",
  "tabler-reserved-line",
  "tabler-resize",
  "tabler-restore",
  "tabler-rewind-backward-10",
  "tabler-rewind-backward-15",
  "tabler-rewind-backward-20",
  "tabler-rewind-backward-30",
  "tabler-rewind-backward-40",
  "tabler-rewind-backward-5",
  "tabler-rewind-backward-50",
  "tabler-rewind-backward-60",
  "tabler-rewind-forward-10",
  "tabler-rewind-forward-15",
  "tabler-rewind-forward-20",
  "tabler-rewind-forward-30",
  "tabler-rewind-forward-40",
  "tabler-rewind-forward-5",
  "tabler-rewind-forward-50",
  "tabler-rewind-forward-60",
  "tabler-ribbon-health",
  "tabler-rings",
  "tabler-ripple-off",
  "tabler-ripple",
  "tabler-road-off",
  "tabler-road-sign",
  "tabler-road",
  "tabler-robot-face",
  "tabler-robot-off",
  "tabler-robot",
  "tabler-rocket-off",
  "tabler-rocket",
  "tabler-roller-skating",
  "tabler-rollercoaster-off",
  "tabler-rollercoaster",
  "tabler-rosette-filled",
  "tabler-rosette-number-0",
  "tabler-rosette-number-1",
  "tabler-rosette-number-2",
  "tabler-rosette-number-3",
  "tabler-rosette-number-4",
  "tabler-rosette-number-5",
  "tabler-rosette-number-6",
  "tabler-rosette-number-7",
  "tabler-rosette-number-8",
  "tabler-rosette-number-9",
  "tabler-rosette",
  "tabler-rotate-2",
  "tabler-rotate-360",
  "tabler-rotate-clockwise-2",
  "tabler-rotate-clockwise",
  "tabler-rotate-dot",
  "tabler-rotate-rectangle",
  "tabler-rotate",
  "tabler-route-2",
  "tabler-route-alt-left",
  "tabler-route-alt-right",
  "tabler-route-off",
  "tabler-route-scan",
  "tabler-route-square-2",
  "tabler-route-square",
  "tabler-route-x-2",
  "tabler-route-x",
  "tabler-route",
  "tabler-router-off",
  "tabler-router",
  "tabler-row-insert-bottom",
  "tabler-row-insert-top",
  "tabler-row-remove",
  "tabler-rss",
  "tabler-rubber-stamp-off",
  "tabler-rubber-stamp",
  "tabler-ruler-2-off",
  "tabler-ruler-2",
  "tabler-ruler-3",
  "tabler-ruler-measure",
  "tabler-ruler-off",
  "tabler-ruler",
  "tabler-run",
  "tabler-rv-truck",
  "tabler-s-turn-down",
  "tabler-s-turn-left",
  "tabler-s-turn-right",
  "tabler-s-turn-up",
  "tabler-sailboat-2",
  "tabler-sailboat-off",
  "tabler-sailboat",
  "tabler-salad",
  "tabler-salt",
  "tabler-satellite-off",
  "tabler-satellite",
  "tabler-sausage",
  "tabler-scale-off",
  "tabler-scale-outline-off",
  "tabler-scale-outline",
  "tabler-scale",
  "tabler-scan-eye",
  "tabler-scan",
  "tabler-schema-off",
  "tabler-schema",
  "tabler-school-bell",
  "tabler-school-off",
  "tabler-school",
  "tabler-scissors-off",
  "tabler-scissors",
  "tabler-scooter-electric",
  "tabler-scooter",
  "tabler-scoreboard",
  "tabler-screen-share-off",
  "tabler-screen-share",
  "tabler-screenshot",
  "tabler-scribble-off",
  "tabler-scribble",
  "tabler-script-minus",
  "tabler-script-plus",
  "tabler-script-x",
  "tabler-script",
  "tabler-scuba-mask-off",
  "tabler-scuba-mask",
  "tabler-sdk",
  "tabler-search-off",
  "tabler-search",
  "tabler-section-sign",
  "tabler-section",
  "tabler-seeding-off",
  "tabler-seeding",
  "tabler-select-all",
  "tabler-select",
  "tabler-selector",
  "tabler-send-off",
  "tabler-send",
  "tabler-seo",
  "tabler-separator-horizontal",
  "tabler-separator-vertical",
  "tabler-separator",
  "tabler-server-2",
  "tabler-server-bolt",
  "tabler-server-cog",
  "tabler-server-off",
  "tabler-server",
  "tabler-servicemark",
  "tabler-settings-2",
  "tabler-settings-automation",
  "tabler-settings-bolt",
  "tabler-settings-cancel",
  "tabler-settings-check",
  "tabler-settings-code",
  "tabler-settings-cog",
  "tabler-settings-dollar",
  "tabler-settings-down",
  "tabler-settings-exclamation",
  "tabler-settings-filled",
  "tabler-settings-heart",
  "tabler-settings-minus",
  "tabler-settings-off",
  "tabler-settings-pause",
  "tabler-settings-pin",
  "tabler-settings-plus",
  "tabler-settings-question",
  "tabler-settings-search",
  "tabler-settings-share",
  "tabler-settings-star",
  "tabler-settings-up",
  "tabler-settings-x",
  "tabler-settings",
  "tabler-shadow-off",
  "tabler-shadow",
  "tabler-shape-2",
  "tabler-shape-3",
  "tabler-shape-off",
  "tabler-shape",
  "tabler-share-2",
  "tabler-share-3",
  "tabler-share-off",
  "tabler-share",
  "tabler-shi-jumping",
  "tabler-shield-bolt",
  "tabler-shield-cancel",
  "tabler-shield-check-filled",
  "tabler-shield-check",
  "tabler-shield-checkered-filled",
  "tabler-shield-checkered",
  "tabler-shield-chevron",
  "tabler-shield-code",
  "tabler-shield-cog",
  "tabler-shield-dollar",
  "tabler-shield-down",
  "tabler-shield-exclamation",
  "tabler-shield-filled",
  "tabler-shield-half-filled",
  "tabler-shield-half",
  "tabler-shield-heart",
  "tabler-shield-lock-filled",
  "tabler-shield-lock",
  "tabler-shield-minus",
  "tabler-shield-off",
  "tabler-shield-pause",
  "tabler-shield-pin",
  "tabler-shield-plus",
  "tabler-shield-question",
  "tabler-shield-search",
  "tabler-shield-share",
  "tabler-shield-star",
  "tabler-shield-up",
  "tabler-shield-x",
  "tabler-shield",
  "tabler-ship-off",
  "tabler-ship",
  "tabler-shirt-filled",
  "tabler-shirt-off",
  "tabler-shirt-sport",
  "tabler-shirt",
  "tabler-shoe-off",
  "tabler-shoe",
  "tabler-shopping-bag-check",
  "tabler-shopping-bag-discount",
  "tabler-shopping-bag-edit",
  "tabler-shopping-bag-exclamation",
  "tabler-shopping-bag-minus",
  "tabler-shopping-bag-plus",
  "tabler-shopping-bag-search",
  "tabler-shopping-bag-x",
  "tabler-shopping-bag",
  "tabler-shopping-cart-bolt",
  "tabler-shopping-cart-cancel",
  "tabler-shopping-cart-check",
  "tabler-shopping-cart-code",
  "tabler-shopping-cart-cog",
  "tabler-shopping-cart-copy",
  "tabler-shopping-cart-discount",
  "tabler-shopping-cart-dollar",
  "tabler-shopping-cart-down",
  "tabler-shopping-cart-exclamation",
  "tabler-shopping-cart-filled",
  "tabler-shopping-cart-heart",
  "tabler-shopping-cart-minus",
  "tabler-shopping-cart-off",
  "tabler-shopping-cart-pause",
  "tabler-shopping-cart-pin",
  "tabler-shopping-cart-plus",
  "tabler-shopping-cart-question",
  "tabler-shopping-cart-search",
  "tabler-shopping-cart-share",
  "tabler-shopping-cart-star",
  "tabler-shopping-cart-up",
  "tabler-shopping-cart-x",
  "tabler-shopping-cart",
  "tabler-shovel",
  "tabler-shredder",
  "tabler-sign-left-filled",
  "tabler-sign-left",
  "tabler-sign-right-filled",
  "tabler-sign-right",
  "tabler-signal-2g",
  "tabler-signal-3g",
  "tabler-signal-4g-plus",
  "tabler-signal-4g",
  "tabler-signal-5g",
  "tabler-signal-6g",
  "tabler-signal-e",
  "tabler-signal-g",
  "tabler-signal-h-plus",
  "tabler-signal-h",
  "tabler-signal-lte",
  "tabler-signature-off",
  "tabler-signature",
  "tabler-sitemap-off",
  "tabler-sitemap",
  "tabler-skateboard-off",
  "tabler-skateboard",
  "tabler-skateboarding",
  "tabler-skull",
  "tabler-slash",
  "tabler-slashes",
  "tabler-sleigh",
  "tabler-slice",
  "tabler-slideshow",
  "tabler-smart-home-off",
  "tabler-smart-home",
  "tabler-smoking-no",
  "tabler-smoking",
  "tabler-snowflake-off",
  "tabler-snowflake",
  "tabler-snowman",
  "tabler-soccer-field",
  "tabler-social-off",
  "tabler-social",
  "tabler-sock",
  "tabler-sofa-off",
  "tabler-sofa",
  "tabler-solar-electricity",
  "tabler-solar-panel-2",
  "tabler-solar-panel",
  "tabler-sort-0-9",
  "tabler-sort-9-0",
  "tabler-sort-a-z",
  "tabler-sort-ascending-2",
  "tabler-sort-ascending-letters",
  "tabler-sort-ascending-numbers",
  "tabler-sort-ascending",
  "tabler-sort-descending-2",
  "tabler-sort-descending-letters",
  "tabler-sort-descending-numbers",
  "tabler-sort-descending",
  "tabler-sort-z-a",
  "tabler-sos",
  "tabler-soup-off",
  "tabler-soup",
  "tabler-source-code",
  "tabler-space-off",
  "tabler-space",
  "tabler-spacing-horizontal",
  "tabler-spacing-vertical",
  "tabler-spade-filled",
  "tabler-spade",
  "tabler-sparkles",
  "tabler-speakerphone",
  "tabler-speedboat",
  "tabler-sphere-off",
  "tabler-sphere-plus",
  "tabler-sphere",
  "tabler-spider",
  "tabler-spiral-off",
  "tabler-spiral",
  "tabler-sport-billard",
  "tabler-spray",
  "tabler-spy-off",
  "tabler-spy",
  "tabler-sql",
  "tabler-square-0-filled",
  "tabler-square-1-filled",
  "tabler-square-2-filled",
  "tabler-square-3-filled",
  "tabler-square-4-filled",
  "tabler-square-5-filled",
  "tabler-square-6-filled",
  "tabler-square-7-filled",
  "tabler-square-8-filled",
  "tabler-square-9-filled",
  "tabler-square-arrow-down-filled",
  "tabler-square-arrow-down",
  "tabler-square-arrow-left-filled",
  "tabler-square-arrow-left",
  "tabler-square-arrow-right-filled",
  "tabler-square-arrow-right",
  "tabler-square-arrow-up-filled",
  "tabler-square-arrow-up",
  "tabler-square-asterisk-filled",
  "tabler-square-asterisk",
  "tabler-square-check-filled",
  "tabler-square-check",
  "tabler-square-chevron-down-filled",
  "tabler-square-chevron-down",
  "tabler-square-chevron-left-filled",
  "tabler-square-chevron-left",
  "tabler-square-chevron-right-filled",
  "tabler-square-chevron-right",
  "tabler-square-chevron-up-filled",
  "tabler-square-chevron-up",
  "tabler-square-chevrons-down-filled",
  "tabler-square-chevrons-down",
  "tabler-square-chevrons-left-filled",
  "tabler-square-chevrons-left",
  "tabler-square-chevrons-right-filled",
  "tabler-square-chevrons-right",
  "tabler-square-chevrons-up-filled",
  "tabler-square-chevrons-up",
  "tabler-square-dot-filled",
  "tabler-square-dot",
  "tabler-square-f0-filled",
  "tabler-square-f0",
  "tabler-square-f1-filled",
  "tabler-square-f1",
  "tabler-square-f2-filled",
  "tabler-square-f2",
  "tabler-square-f3-filled",
  "tabler-square-f3",
  "tabler-square-f4-filled",
  "tabler-square-f4",
  "tabler-square-f5-filled",
  "tabler-square-f5",
  "tabler-square-f6-filled",
  "tabler-square-f6",
  "tabler-square-f7-filled",
  "tabler-square-f7",
  "tabler-square-f8-filled",
  "tabler-square-f8",
  "tabler-square-f9-filled",
  "tabler-square-f9",
  "tabler-square-filled",
  "tabler-square-forbid-2",
  "tabler-square-forbid",
  "tabler-square-half",
  "tabler-square-key",
  "tabler-square-letter-a",
  "tabler-square-letter-b",
  "tabler-square-letter-c",
  "tabler-square-letter-d",
  "tabler-square-letter-e",
  "tabler-square-letter-f",
  "tabler-square-letter-g",
  "tabler-square-letter-h",
  "tabler-square-letter-i",
  "tabler-square-letter-j",
  "tabler-square-letter-k",
  "tabler-square-letter-l",
  "tabler-square-letter-m",
  "tabler-square-letter-n",
  "tabler-square-letter-o",
  "tabler-square-letter-p",
  "tabler-square-letter-q",
  "tabler-square-letter-r",
  "tabler-square-letter-s",
  "tabler-square-letter-t",
  "tabler-square-letter-u",
  "tabler-square-letter-v",
  "tabler-square-letter-w",
  "tabler-square-letter-x",
  "tabler-square-letter-y",
  "tabler-square-letter-z",
  "tabler-square-minus-filled",
  "tabler-square-minus",
  "tabler-square-number-0",
  "tabler-square-number-1",
  "tabler-square-number-2",
  "tabler-square-number-3",
  "tabler-square-number-4",
  "tabler-square-number-5",
  "tabler-square-number-6",
  "tabler-square-number-7",
  "tabler-square-number-8",
  "tabler-square-number-9",
  "tabler-square-off",
  "tabler-square-plus-2",
  "tabler-square-plus",
  "tabler-square-root-2",
  "tabler-square-root",
  "tabler-square-rotated-filled",
  "tabler-square-rotated-forbid-2",
  "tabler-square-rotated-forbid",
  "tabler-square-rotated-off",
  "tabler-square-rotated",
  "tabler-square-rounded-arrow-down-filled",
  "tabler-square-rounded-arrow-down",
  "tabler-square-rounded-arrow-left-filled",
  "tabler-square-rounded-arrow-left",
  "tabler-square-rounded-arrow-right-filled",
  "tabler-square-rounded-arrow-right",
  "tabler-square-rounded-arrow-up-filled",
  "tabler-square-rounded-arrow-up",
  "tabler-square-rounded-check-filled",
  "tabler-square-rounded-check",
  "tabler-square-rounded-chevron-down-filled",
  "tabler-square-rounded-chevron-down",
  "tabler-square-rounded-chevron-left-filled",
  "tabler-square-rounded-chevron-left",
  "tabler-square-rounded-chevron-right-filled",
  "tabler-square-rounded-chevron-right",
  "tabler-square-rounded-chevron-up-filled",
  "tabler-square-rounded-chevron-up",
  "tabler-square-rounded-chevrons-down-filled",
  "tabler-square-rounded-chevrons-down",
  "tabler-square-rounded-chevrons-left-filled",
  "tabler-square-rounded-chevrons-left",
  "tabler-square-rounded-chevrons-right-filled",
  "tabler-square-rounded-chevrons-right",
  "tabler-square-rounded-chevrons-up-filled",
  "tabler-square-rounded-chevrons-up",
  "tabler-square-rounded-filled",
  "tabler-square-rounded-letter-a",
  "tabler-square-rounded-letter-b",
  "tabler-square-rounded-letter-c",
  "tabler-square-rounded-letter-d",
  "tabler-square-rounded-letter-e",
  "tabler-square-rounded-letter-f",
  "tabler-square-rounded-letter-g",
  "tabler-square-rounded-letter-h",
  "tabler-square-rounded-letter-i",
  "tabler-square-rounded-letter-j",
  "tabler-square-rounded-letter-k",
  "tabler-square-rounded-letter-l",
  "tabler-square-rounded-letter-m",
  "tabler-square-rounded-letter-n",
  "tabler-square-rounded-letter-o",
  "tabler-square-rounded-letter-p",
  "tabler-square-rounded-letter-q",
  "tabler-square-rounded-letter-r",
  "tabler-square-rounded-letter-s",
  "tabler-square-rounded-letter-t",
  "tabler-square-rounded-letter-u",
  "tabler-square-rounded-letter-v",
  "tabler-square-rounded-letter-w",
  "tabler-square-rounded-letter-x",
  "tabler-square-rounded-letter-y",
  "tabler-square-rounded-letter-z",
  "tabler-square-rounded-minus-2",
  "tabler-square-rounded-minus-filled",
  "tabler-square-rounded-minus",
  "tabler-square-rounded-number-0-filled",
  "tabler-square-rounded-number-0",
  "tabler-square-rounded-number-1-filled",
  "tabler-square-rounded-number-1",
  "tabler-square-rounded-number-2-filled",
  "tabler-square-rounded-number-2",
  "tabler-square-rounded-number-3-filled",
  "tabler-square-rounded-number-3",
  "tabler-square-rounded-number-4-filled",
  "tabler-square-rounded-number-4",
  "tabler-square-rounded-number-5-filled",
  "tabler-square-rounded-number-5",
  "tabler-square-rounded-number-6-filled",
  "tabler-square-rounded-number-6",
  "tabler-square-rounded-number-7-filled",
  "tabler-square-rounded-number-7",
  "tabler-square-rounded-number-8-filled",
  "tabler-square-rounded-number-8",
  "tabler-square-rounded-number-9-filled",
  "tabler-square-rounded-number-9",
  "tabler-square-rounded-plus-2",
  "tabler-square-rounded-plus-filled",
  "tabler-square-rounded-plus",
  "tabler-square-rounded-x-filled",
  "tabler-square-rounded-x",
  "tabler-square-rounded",
  "tabler-square-toggle-horizontal",
  "tabler-square-toggle",
  "tabler-square-x-filled",
  "tabler-square-x",
  "tabler-square",
  "tabler-squares-diagonal",
  "tabler-squares-filled",
  "tabler-stack-2",
  "tabler-stack-3",
  "tabler-stack-pop",
  "tabler-stack-push",
  "tabler-stack",
  "tabler-stairs-down",
  "tabler-stairs-up",
  "tabler-stairs",
  "tabler-star-filled",
  "tabler-star-half-filled",
  "tabler-star-half",
  "tabler-star-off",
  "tabler-star",
  "tabler-stars-filled",
  "tabler-stars-off",
  "tabler-stars",
  "tabler-status-change",
  "tabler-steam",
  "tabler-steering-wheel-off",
  "tabler-steering-wheel",
  "tabler-step-into",
  "tabler-step-out",
  "tabler-stereo-glasses",
  "tabler-stethoscope-off",
  "tabler-stethoscope",
  "tabler-sticker",
  "tabler-storm-off",
  "tabler-storm",
  "tabler-stretching-2",
  "tabler-stretching",
  "tabler-strikethrough",
  "tabler-submarine",
  "tabler-subscript",
  "tabler-subtask",
  "tabler-sum-off",
  "tabler-sum",
  "tabler-sun-electricity",
  "tabler-sun-filled",
  "tabler-sun-high",
  "tabler-sun-low",
  "tabler-sun-moon",
  "tabler-sun-off",
  "tabler-sun-wind",
  "tabler-sun",
  "tabler-sunglasses",
  "tabler-sunrise",
  "tabler-sunset-2",
  "tabler-sunset",
  "tabler-superscript",
  "tabler-svg",
  "tabler-swimming",
  "tabler-swipe",
  "tabler-switch-2",
  "tabler-switch-3",
  "tabler-switch-horizontal",
  "tabler-switch-vertical",
  "tabler-switch",
  "tabler-sword-off",
  "tabler-sword",
  "tabler-swords",
  "tabler-table-alias",
  "tabler-table-column",
  "tabler-table-down",
  "tabler-table-export",
  "tabler-table-filled",
  "tabler-table-heart",
  "tabler-table-import",
  "tabler-table-minus",
  "tabler-table-off",
  "tabler-table-options",
  "tabler-table-plus",
  "tabler-table-row",
  "tabler-table-share",
  "tabler-table-shortcut",
  "tabler-table",
  "tabler-tag-off",
  "tabler-tag-starred",
  "tabler-tag",
  "tabler-tags-off",
  "tabler-tags",
  "tabler-tallymark-1",
  "tabler-tallymark-2",
  "tabler-tallymark-3",
  "tabler-tallymark-4",
  "tabler-tallymarks",
  "tabler-tank",
  "tabler-target-arrow",
  "tabler-target-off",
  "tabler-target",
  "tabler-teapot",
  "tabler-telescope-off",
  "tabler-telescope",
  "tabler-temperature-celsius",
  "tabler-temperature-fahrenheit",
  "tabler-temperature-minus",
  "tabler-temperature-off",
  "tabler-temperature-plus",
  "tabler-temperature",
  "tabler-template-off",
  "tabler-template",
  "tabler-tent-off",
  "tabler-tent",
  "tabler-terminal-2",
  "tabler-terminal",
  "tabler-test-pipe-2",
  "tabler-test-pipe-off",
  "tabler-test-pipe",
  "tabler-tex",
  "tabler-text-caption",
  "tabler-text-color",
  "tabler-text-decrease",
  "tabler-text-direction-ltr",
  "tabler-text-direction-rtl",
  "tabler-text-increase",
  "tabler-text-orientation",
  "tabler-text-plus",
  "tabler-text-recognition",
  "tabler-text-resize",
  "tabler-text-scan-2",
  "tabler-text-size",
  "tabler-text-spellcheck",
  "tabler-text-wrap-disabled",
  "tabler-text-wrap",
  "tabler-texture",
  "tabler-theater",
  "tabler-thermometer",
  "tabler-thumb-down-filled",
  "tabler-thumb-down-off",
  "tabler-thumb-down",
  "tabler-thumb-up-filled",
  "tabler-thumb-up-off",
  "tabler-thumb-up",
  "tabler-tic-tac",
  "tabler-ticket-off",
  "tabler-ticket",
  "tabler-tie",
  "tabler-tilde",
  "tabler-tilt-shift-off",
  "tabler-tilt-shift",
  "tabler-time-duration-0",
  "tabler-time-duration-10",
  "tabler-time-duration-15",
  "tabler-time-duration-30",
  "tabler-time-duration-45",
  "tabler-time-duration-5",
  "tabler-time-duration-60",
  "tabler-time-duration-90",
  "tabler-time-duration-off",
  "tabler-timeline-event-exclamation",
  "tabler-timeline-event-filled",
  "tabler-timeline-event-minus",
  "tabler-timeline-event-plus",
  "tabler-timeline-event-text",
  "tabler-timeline-event-x",
  "tabler-timeline-event",
  "tabler-timeline",
  "tabler-tir",
  "tabler-toggle-left",
  "tabler-toggle-right",
  "tabler-toilet-paper-off",
  "tabler-toilet-paper",
  "tabler-toml",
  "tabler-tool",
  "tabler-tools-kitchen-2-off",
  "tabler-tools-kitchen-2",
  "tabler-tools-kitchen-off",
  "tabler-tools-kitchen",
  "tabler-tools-off",
  "tabler-tools",
  "tabler-tooltip",
  "tabler-topology-bus",
  "tabler-topology-complex",
  "tabler-topology-full-hierarchy",
  "tabler-topology-full",
  "tabler-topology-ring-2",
  "tabler-topology-ring-3",
  "tabler-topology-ring",
  "tabler-topology-star-2",
  "tabler-topology-star-3",
  "tabler-topology-star-ring-2",
  "tabler-topology-star-ring-3",
  "tabler-topology-star-ring",
  "tabler-topology-star",
  "tabler-torii",
  "tabler-tornado",
  "tabler-tournament",
  "tabler-tower-off",
  "tabler-tower",
  "tabler-track",
  "tabler-tractor",
  "tabler-trademark",
  "tabler-traffic-cone-off",
  "tabler-traffic-cone",
  "tabler-traffic-lights-off",
  "tabler-traffic-lights",
  "tabler-train",
  "tabler-transfer-in",
  "tabler-transfer-out",
  "tabler-transfer-vertical",
  "tabler-transfer",
  "tabler-transform-filled",
  "tabler-transform",
  "tabler-transition-bottom",
  "tabler-transition-left",
  "tabler-transition-right",
  "tabler-transition-top",
  "tabler-trash-filled",
  "tabler-trash-off",
  "tabler-trash-x-filled",
  "tabler-trash-x",
  "tabler-trash",
  "tabler-treadmill",
  "tabler-tree",
  "tabler-trees",
  "tabler-trekking",
  "tabler-trending-down-2",
  "tabler-trending-down-3",
  "tabler-trending-down",
  "tabler-trending-up-2",
  "tabler-trending-up-3",
  "tabler-trending-up",
  "tabler-triangle-filled",
  "tabler-triangle-inverted-filled",
  "tabler-triangle-inverted",
  "tabler-triangle-minus-2",
  "tabler-triangle-minus",
  "tabler-triangle-off",
  "tabler-triangle-plus-2",
  "tabler-triangle-plus",
  "tabler-triangle-square-circle-filled",
  "tabler-triangle-square-circle",
  "tabler-triangle",
  "tabler-triangles",
  "tabler-trident",
  "tabler-trolley",
  "tabler-trophy-filled",
  "tabler-trophy-off",
  "tabler-trophy",
  "tabler-trowel",
  "tabler-truck-delivery",
  "tabler-truck-loading",
  "tabler-truck-off",
  "tabler-truck-return",
  "tabler-truck",
  "tabler-txt",
  "tabler-typography-off",
  "tabler-typography",
  "tabler-ufo-off",
  "tabler-ufo",
  "tabler-umbrella-filled",
  "tabler-umbrella-off",
  "tabler-umbrella",
  "tabler-underline",
  "tabler-universe",
  "tabler-unlink",
  "tabler-upload",
  "tabler-urgent",
  "tabler-usb",
  "tabler-user-bolt",
  "tabler-user-cancel",
  "tabler-user-check",
  "tabler-user-circle",
  "tabler-user-code",
  "tabler-user-cog",
  "tabler-user-dollar",
  "tabler-user-down",
  "tabler-user-edit",
  "tabler-user-exclamation",
  "tabler-user-filled",
  "tabler-user-heart",
  "tabler-user-hexagon",
  "tabler-user-minus",
  "tabler-user-off",
  "tabler-user-pause",
  "tabler-user-pentagon",
  "tabler-user-pin",
  "tabler-user-plus",
  "tabler-user-question",
  "tabler-user-scan",
  "tabler-user-search",
  "tabler-user-share",
  "tabler-user-shield",
  "tabler-user-square-rounded",
  "tabler-user-square",
  "tabler-user-star",
  "tabler-user-up",
  "tabler-user-x",
  "tabler-user",
  "tabler-users-group",
  "tabler-users-minus",
  "tabler-users-plus",
  "tabler-users",
  "tabler-uv-index",
  "tabler-ux-circle",
  "tabler-vaccine-bottle-off",
  "tabler-vaccine-bottle",
  "tabler-vaccine-off",
  "tabler-vaccine",
  "tabler-vacuum-cleaner",
  "tabler-variable-minus",
  "tabler-variable-off",
  "tabler-variable-plus",
  "tabler-variable",
  "tabler-vector-bezier-2",
  "tabler-vector-bezier-arc",
  "tabler-vector-bezier-circle",
  "tabler-vector-bezier",
  "tabler-vector-off",
  "tabler-vector-spline",
  "tabler-vector-triangle-off",
  "tabler-vector-triangle",
  "tabler-vector",
  "tabler-venus",
  "tabler-versions-filled",
  "tabler-versions-off",
  "tabler-versions",
  "tabler-video-minus",
  "tabler-video-off",
  "tabler-video-plus",
  "tabler-video",
  "tabler-view-360-off",
  "tabler-view-360",
  "tabler-viewfinder-off",
  "tabler-viewfinder",
  "tabler-viewport-narrow",
  "tabler-viewport-wide",
  "tabler-vinyl",
  "tabler-vip-off",
  "tabler-vip",
  "tabler-virus-off",
  "tabler-virus-search",
  "tabler-virus",
  "tabler-vocabulary-off",
  "tabler-vocabulary",
  "tabler-volcano",
  "tabler-volume-2",
  "tabler-volume-3",
  "tabler-volume-off",
  "tabler-volume",
  "tabler-vs",
  "tabler-walk",
  "tabler-wall-off",
  "tabler-wall",
  "tabler-wallet-off",
  "tabler-wallet",
  "tabler-wallpaper-off",
  "tabler-wallpaper",
  "tabler-wand-off",
  "tabler-wand",
  "tabler-wash-dry-1",
  "tabler-wash-dry-2",
  "tabler-wash-dry-3",
  "tabler-wash-dry-a",
  "tabler-wash-dry-dip",
  "tabler-wash-dry-f",
  "tabler-wash-dry-flat",
  "tabler-wash-dry-hang",
  "tabler-wash-dry-off",
  "tabler-wash-dry-p",
  "tabler-wash-dry-shade",
  "tabler-wash-dry-w",
  "tabler-wash-dry",
  "tabler-wash-dryclean-off",
  "tabler-wash-dryclean",
  "tabler-wash-eco",
  "tabler-wash-gentle",
  "tabler-wash-hand",
  "tabler-wash-machine",
  "tabler-wash-off",
  "tabler-wash-press",
  "tabler-wash-temperature-1",
  "tabler-wash-temperature-2",
  "tabler-wash-temperature-3",
  "tabler-wash-temperature-4",
  "tabler-wash-temperature-5",
  "tabler-wash-temperature-6",
  "tabler-wash-tumble-dry",
  "tabler-wash-tumble-off",
  "tabler-wash",
  "tabler-waterpolo",
  "tabler-wave-saw-tool",
  "tabler-wave-sine",
  "tabler-wave-square",
  "tabler-waves-electricity",
  "tabler-webhook-off",
  "tabler-webhook",
  "tabler-weight",
  "tabler-wheel",
  "tabler-wheelchair-off",
  "tabler-wheelchair",
  "tabler-whirl",
  "tabler-wifi-0",
  "tabler-wifi-1",
  "tabler-wifi-2",
  "tabler-wifi-off",
  "tabler-wifi",
  "tabler-wind-electricity",
  "tabler-wind-off",
  "tabler-wind",
  "tabler-windmill-filled",
  "tabler-windmill-off",
  "tabler-windmill",
  "tabler-window-maximize",
  "tabler-window-minimize",
  "tabler-window-off",
  "tabler-window",
  "tabler-windsock",
  "tabler-wiper-wash",
  "tabler-wiper",
  "tabler-woman",
  "tabler-wood",
  "tabler-world-bolt",
  "tabler-world-cancel",
  "tabler-world-check",
  "tabler-world-code",
  "tabler-world-cog",
  "tabler-world-dollar",
  "tabler-world-down",
  "tabler-world-download",
  "tabler-world-exclamation",
  "tabler-world-heart",
  "tabler-world-latitude",
  "tabler-world-longitude",
  "tabler-world-minus",
  "tabler-world-off",
  "tabler-world-pause",
  "tabler-world-pin",
  "tabler-world-plus",
  "tabler-world-question",
  "tabler-world-search",
  "tabler-world-share",
  "tabler-world-star",
  "tabler-world-up",
  "tabler-world-upload",
  "tabler-world-www",
  "tabler-world-x",
  "tabler-world",
  "tabler-wrecking-ball",
  "tabler-writing-off",
  "tabler-writing-sign-off",
  "tabler-writing-sign",
  "tabler-writing",
  "tabler-x",
  "tabler-xbox-a",
  "tabler-xbox-b",
  "tabler-xbox-x",
  "tabler-xbox-y",
  "tabler-xd",
  "tabler-xxx",
  "tabler-yin-yang-filled",
  "tabler-yin-yang",
  "tabler-yoga",
  "tabler-zeppelin-off",
  "tabler-zeppelin",
  "tabler-zip",
  "tabler-zodiac-aquarius",
  "tabler-zodiac-aries",
  "tabler-zodiac-cancer",
  "tabler-zodiac-capricorn",
  "tabler-zodiac-gemini",
  "tabler-zodiac-leo",
  "tabler-zodiac-libra",
  "tabler-zodiac-pisces",
  "tabler-zodiac-sagittarius",
  "tabler-zodiac-scorpio",
  "tabler-zodiac-taurus",
  "tabler-zodiac-virgo",
  "tabler-zoom-cancel",
  "tabler-zoom-check-filled",
  "tabler-zoom-check",
  "tabler-zoom-code",
  "tabler-zoom-exclamation",
  "tabler-zoom-filled",
  "tabler-zoom-in-area-filled",
  "tabler-zoom-in-area",
  "tabler-zoom-in-filled",
  "tabler-zoom-in",
  "tabler-zoom-money",
  "tabler-zoom-out-area",
  "tabler-zoom-out-filled",
  "tabler-zoom-out",
  "tabler-zoom-pan",
  "tabler-zoom-question",
  "tabler-zoom-replace",
  "tabler-zoom-reset",
  "tabler-zoom-scan",
  "tabler-zzz-off",
  "tabler-zzz",
];

export const tablerIcons = new Set(tablerIconNames);
